const ProductCategoriesSummary = {
    categories: [
      {
        id: "barcode-scanners",
        title: "Barcode Scanners",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners.png`,
        description: "DEMONSTRATING EFFICIENCY WITH EVERY TRANSACTION.",
        features: [
          {
            name: "Ergonomic Design", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/items/1.png`,
          },
          {
            name: "Robust and Durable",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/items/4.png`,
          },
          {
            name: "Ultra Scan Decoding Technology",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/items/5.png`,
          },
          {
            name: "High Sensitive Image Sensor", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/items/2.png`,
          },
          {
            name: "Direct Plug And Play",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/items/3.png`,
          },
        ],
        products: [
          {
            id: "bs-l100-plus",
            name: "BS-L100 PLUS",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-L100.png`,
            shortDescription: "HANDHELD 1D SCANNER",
          },
          {
            id: "bs-i201g-lite",
            name: "BS i201G Lite",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-i201g-Lite.png`,
            shortDescription: "2D WIRELESS SCANNER",
          },
          {
            id: "bs-l150s-wl",
            name: "BS-L150s WL",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150.png`,
            shortDescription: "1D LASER WIRELESS SCANNER",
          },
          {
            id: "bs-i201s-wl",
            name: "BS-i201s WL",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-i201s-WL.png`,
            shortDescription: "2D WIRELESS BARCODE SCANNER",
          },
          {
            id: "bs-i302-g",
            name: "BS-i302 G",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-i302g.png`,
            shortDescription: "2D OMNI-DIRECTIONAL SCANNER",
          }
        ]
      },
      {
        id: "touch-pos-systems",
        title: "Touch POS Systems",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems.png`,
        description: "ROBUST, RELIABLE, AND CUTTING EDGE BILLING SUPPORT.",
        features: [
          {
            name: "Touch Screen", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/items/Touch-Screen.png`,
          },
          {
            name: "Fanless Design",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/items/Fanless-Design.png`,
          },
          {
            name: "Multi-tasking Pinnacle Performance",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/items/Multitasking.png`,
          }
        ],
        products: [
          {
            id: "tp-415Ca",
            name: "TP 415cA",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-415CA.png`,
          },
          {
            id: "tp-482c-aio",
            name: "TP 482c AIO",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO.png`,
          },
          {
            id: "tp-4103",
            name: "TP 4103",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103.png`,
          }
        ]
      },
      {
        id: "thermal-receipt-printers",
        title: "Thermal Receipt Printers",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers.png`,
        description: "A NEW STANDARD IN BILLING.",
        features: [
          {
            name: "Robust Build Quality", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/items/1.png`,
          },
          {
            name: "User-friendly & Portable",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/items/2.png`,
          },
          {
            name: "Superior Printing Speed",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/items/3.png`,
          },
          {
            name: "Easy to Maintain",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/items/4.png`,
          },
          {
            name: "Low Power Consumption",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/items/5.png`,
          }
        ],
        products: [
          {
            id: "rp-3220-star",
            name: "RP 3220 STAR",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR.png`,
          },
          {
            id: "rp-3200-plus",
            name: "RP 3200 PLUS",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-plus.png`,
          },
          {
            id: "rp-3230",
            name: "RP 3230",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230.png`,
          }
        ]
      },
      {
        id: "printers",
        title: "Printers",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers.png`,
        description: "BUILT TO ENDURE. DESIGNED FOR EFFICIENCY.",
        features: [
          {
            name: "Minimal Cost", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/items/1.png`,
          },
          {
            name: "Maximum Productivity",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/items/2.png`,
          },
          {
            name: "Superior Reliability",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/items/3.png`,
          },
          {
            name: "Sustained Dependability",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/items/4.png`,
          },
          {
            name: "Value for Money",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/items/5.png`,
          }
        ],
        products: [
          {
            id: "msp-270-classic-plus",
            name: "MSP 270 Classic Plus",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus.png`,
          },
          {
            id: "rp-45-shoppe-bt",
            name: "RP 45 Shoppe BT",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT.png`,
          },
          {
            id: "rp-45-shoppe",
            name: "RP 45 Shoppe",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE.png`,
          },
          {
            id: "speed-40-plus",
            name: "SPEED 40 PLUS",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS.png`,
          },
          {
            id: "speed-40-star",
            name: "SPEED 40 STAR",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR.png`,
          }
        ]
      },
      {
        id: "label-printers",
        title: "Lable Printers",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers.png`,
        description: "LABELLED TO LAST.",
        features: [
          {
            name: "Efficient & Versatile", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/items/5.png`,
          },
          {
            name: "Robust & Durable",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/items/1.png`,
          },
          {
            name: "Superior Printing Speed",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/items/2.png`,
          },
          {
            name: "User-friendly Design",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/items/3.png`,
          },
          {
            name: "Value for Money",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/items/4.png`,
          }
        ],
        products: [
          {
            id: "lp-46-neo",
            name: "LP 46 NEO",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO.png`,
            shortDescription: "DESKTOP LABEL PRINTER",
          },
          {
            id: "lp-46-lite",
            name: "LP 46 LITE",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE.png`,
            shortDescription: "DESKTOP LABEL PRINTER",
          },
          {
            id: "zenpert-4T520",
            name: "ZENPERT 4T520",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520.png`,
            shortDescription: "DESKTOP LABEL PRINTER",
          }
        ]
      },
      {
        id: "keyboards-and-mice",
        title: "Keyboards and Mice",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice.png`,
        description: "EFFORTLESS OPERATIONS. ENDURING QUALITY. FLAWLESS FUNCTION.",
        features: [
          {
            name: "10 Million+ Strokes per key", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/items/1.png`,
          },
          {
            name: "Sculpted keycap with click on touch feeling",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/items/3.png`,
          },
          {
            name: "Choice of Interfaces USB | PS2",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/items/2.png`,
          },
          {
            name: "Feather Touch", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/items/4.png`,
          },
          {
            name: "Ergonomic Design",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/items/5.png`,
          },
          {
            name: "Plug & Play USB Interface",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/items/6.png`,
          }
        ],
        products: [
          {
            id: "gold",
            name: "GOLD",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold.png`,
            shortDescription: "MECHANICAL KEYBOARD",
          },
          {
            id: "platina",
            name: "Platina",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina.png`,
            shortDescription: "MECHANICAL KEYBOARD",
          },
          {
            id: "champ-hd",
            name: "CHAMP-HD",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd.png`,
            shortDescription: "MEMBRANE HEAVY DUTY KEYBOARD",
          }
          ,
          {
            id: "champ-elite-wireless-combo",
            name: "CHAMP ELITE WIRELESS COMBO",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO.png`,
            shortDescription: "KEYBOARD & MOUSE",
          },
          {
            id: "champ-m120",
            name: "CHAMP M120",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120.png`,
            shortDescription: "OPTICAL MOUSE",
          },
          {
            id: "wm-416",
            name: "WM-416",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse.png`,
            shortDescription: "WIRELESS MOUSE",
          }
        ]
      },
      {
        id: "currency-counter",
        title: "Currency Counter",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter.png`,
        description: "ABSOLUTE PRECISION. UNMATCHED SPEED.",
        features: [
          {
            name: "Suitable for Heavy Duty Operations", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/items/1.png`,
          },
          {
            name: "Super Fast Currency Counting: 1200 notes/min",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/items/6.png`,
          },
          {
            name: "Supports batch function with in-built display",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/items/2.png`,
          },
          {
            name: "Detects & Counts both old and new currencies", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/items/3.png`,
          },
          {
            name: "Has automatic count mode",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/items/4.png`,
          },
          {
            name: "Easy Maintenance: Self check function",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/items/5.png`,
          }
        ],
        products: [
          {
            id: "cc-232-classic",
            name: "CC 232 CLASSIC",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic.png`,
          },
          {
            id: "cc-453-star+",
            name: "CC 453 STAR+",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP.png`,
          },
          {
            id: "cc-232-classic+",
            name: "CC 232 CLASSIC+",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP.png`,
          }
        ]
      },
      {
        id: "e-cash-registers",
        title: "E-Cash Registers",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers.png`,
        description: "DYNAMIC PERFORMANCE. DELIGHTFUL OUTPUT.",
        features: [
          {
            name: "Non-stop Billing", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/items/1.png`,
          },
          {
            name: "Prints different types of reports",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/items/2.png`,
          }
        ],
        products: [
          {
            id: "pt-262",
            name: "PT 262",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262.png`,
          },
          {
            id: "pt-235",
            name: "PT 235",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235.png`,
          }
        ]
      },
      {
        id: "mobile-printers",
        title: "Mobile Printers",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers.png`,
        description: "SMARTEST WAY TO PRINT LABELS AND RECEIPTS.",
        features: [
          {
            name: "Billing", 
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/items/1.png`,
          },
          {
            name: "Ticketing",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/items/4.png`,
          },
          {
            name: "Retail",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/items/3.png`,
          },
          {
            name: "Hospitality",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/items/2.png`,
          }
        ],
        products: [
          {
            id: "mp-280-lite",
            name: "MP 280 LITE",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE.png`,
            shortDescription: "2\" MOBILE PRINTER",
          },
          {
            id: "mlp-360",
            name: "MLP 360",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360.png`,
            shortDescription: "3\" MOBILE PRINTER",
          },
          {
            id: "mp-22",
            name: "MP 22",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22.png`,
            shortDescription: "2\" MOBILE PRINTER",
          },
          {
            id: "mlp-370",
            name: "MLP 370",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370.png`,
          }
        ]
      },
      {
        id: "cash-drawers",
        products: [
          {
            id: "champ-cd-58410g"
          }
        ]
      },
      {
        id: "genuine-spares",
        title: "Genuine Spares",
        image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares.png`,
        description: "USE GENUINE SPARES FOR LONGER LIFE.",
        products: [
          {
            id: "thermal-paper-roll",
            name: "THERMAL PAPER ROLL",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares/THERMAL-PAPER-ROLL.png`,
            shortDescription: "Longer lasting printout\nBetter print head production\nCustomized roll length\nAvailable in 2.25, 3 & 4 inches",
          },
          {
            id: "print-head",
            name: "PRINT HEAD",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares/PRINT-HEAD.png`,
            shortDescription: "Long life - 200 million characters\nSuperior print quality\nHigh copy capability",
          },
          {
            id: "thermal-transfer-ribbon-blue",
            name: "THERMAL TRANSFER RIBBON BLUE",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares/THERMAL-TRANSFER-RIBBON-BLUE.png`,
            shortDescription: "Longer lasting printout\nBetter print head production\nCustomized roll length",
          },
          {
            id: "ribbon",
            name: "RIBBON",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares/RIBBON.png`,
            shortDescription: "Indelible ink for security printing\nHigh density long life ribbon",
          },
          {
            id: "ink-bank",
            name: "INK BANK",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares/INK-BANK.png`,
          }
        ]
      }
    ]
  };
  
  export default ProductCategoriesSummary;