const JourneyContent = {
  journey: [
    {
      year: "1993",
      title: "Establishment",
      contents: ["Two young technocrats started a joint venture, with the philosophy:"],
      italic: ["Leadership is in the Action not in the Position."],
      
    },
    {
      year: "1998",
      title: "HP Retail Showroom",
      contents: ["We were the FIRST Company in Baroda to Start the Retail showroom of IT Products. WIPRO Ltd. tied up with us and we were the FIRST Wipro Peripherals Retail Showroom Partner in India.",
      "With the same spirit and Zeal we stepped forward and joined hands with HP. Again we were the FIRST Retail Showroom Partner of HP in Baroda."],
      italic: [""],
      
    },
    {
      year: "2001",
      title: "The FAMILY IT Store - Retail Expansion",
      contents: ["We expanded our showroom & Once again we came up with a new concept which we proudly call \"THE FAMILY IT STORE\". And here we kept latest IT Products with LIVE DEMO, because we always Believe that ... \"SEEING IS BELIEVING\"."],
      italic: [""],
      
    },
    {
      year: "2002",
      title: "LG Regional Distributor",
      contents: ["With our Creative Management thinking, Advanced technology adoption, and a positive risk taking attitude, we once again got success in setting us apart as the leader. For the FIRST time MD (Mr.Kwang-Ro Kim) of LG Electronics India Ltd. visited our showroom and as a tradition the new concept of Regional Distribution was emerged here @ AASH'NIK & was chosen as the FIRST ever LG Regional Distributor for Vadodara on all India basis."],
      italic: [""],
      
    },
    {
      year: "2002",
      title: "TVSe Regional Distributor Partner (RDP)",
      contents: ["TVS Electronics & ace-Wipro came forward and we became Exclusive TVSE - RDP (Re - Distributor Partner) and ace-WeP Networking product's Distributor."],
      italic: [""],
      
    },
    {
      year: "2003",
      title: "LG CDMA & FWT Authorized Service Center",
      contents: ["The Indian Telecommunication market was growing very fast and It was projected to grow further, And at this time we looked for a diversified Business Opportunity in Telecom Service Industry. We been appointed by as an Exclusive LG CDMA & FWT Authorized Service Center for VADODARA District."],
      italic: [""],
      
    },
    {
      year: "2003",
      title: "HP SUB-Distributor",
      contents: ["We had our IT showroom in the Prime locality of Vadodara city, till now we were focusing on Retail and B2B Sales. Looking at our business volume and loyalty towards HP brand, we been appointed as a SUB-Distributor for HP Peripherals & HP-CompaQ Presario PCs for Central Gujarat, that covers 4 Districts Vadodara, Anand, Panchmahal, & Bharuch."],
      italic: [""],
      
    },
    {
      year: "2005",
      title: "HP World",
      contents: ["Due to exclusive HP product selling & Promotion activities, HP appointed AASH'NIK as Premium Retail the “HP WORLD” partner."],
      italic: [""],
      
    },
    {
      year: "2007",
      title: "Prestigious Awards",
      contents: ["We were expanding our Retail presence in IT market. Throughout the year, we did lots of Mass Marketing Retail activities in a very creative way, using all kind of Media & simultaneously we continued nurturing our relationship with Channel partners to get a strong business bond. These were noticed & had grabbed two prestigious awards:"],
      italic: ["1. CRN Channel Award for Best Retailer - West Zone, India.",
      "2. DQ Week Award for Best Channel Partner - Vadodara, Gujarat, India."],
      
    },
    {
      year: "2011",
      title: "Idea MBB Distributor",
      contents: ["India is one of the few countries where Internet adaption has been quicker, with 3G services launch in India, it is going to be an exciting phase for the Indian telecom market. To Cater the IT Channel Market, we had become MBB Distributor of Idea Cellular Limited - Aditya Birla Group for their 3G Data Cards Sales & Distribution."],
      italic: [""],
      
    },
    {
      year: "2012",
      title: "TVSe POS Company Distributor Partner (CDP)",
      contents: ["In addition to our legacy products, we had also added a range of services covering the entire spectrum of Point-of-Sale(POS) solutions including Transaction Automation Solutions, Installation and Demo Solutions, Repair Engineering Solutions, etc."],
      italic: [""],
      
    },
    {
      year: "Now",
      title: "Our Journey Continues...",
      contents: [
        "Entering the present year, our journey at AASH'NIK continues to be marked by growth, innovation, and unwavering commitment to excellence. As we navigate the dynamic business landscape of today, we remain guided by our core values and principles, ensuring that integrity, transparency, and customer satisfaction always remain at the forefront of everything we do.",
        "Together, we embark on this journey with optimism, confidence, and a shared vision for a brighter future.",
      ],
      italic: [""]
    }
    
  ]
};

export default JourneyContent;