const ProductSummaryPageContent = {
    title: 'Products',
    contents: [
      "Efficient point of sale operations are vital for any business. With our wide range of POS products and accessories, you can optimize your checkout process, manage inventory, and track sales effortlessly. Whether you're a small business owner or a large enterprise, we have the right POS solutions to meet your needs.",
      "Ready to take your business to the next level? Explore our POS product catalog, and if you have any questions or need guidance, please don't hesitate to reach out to us and we’ll get back to you as soon as possible.",
      "Start improving your business's efficiency and customer satisfaction today with our top-notch POS products!"
    ],
    products: [
      {
        title: "Barcode Scanners",
        contents: ["Barcode Scanners are designed bearing in mind the volume of operations and man hours involved in the retail business. The scanners offer you the freedom and convenience to capture the codes effortlessly in a steadfast manner – without any loss of information. Many clients repose their trust in our Barcode Scanners, thanks to their build quality, convenience and enhanced efficiency."],
        productPath: "/products/barcode-scanners",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners.png",
        isLightMode: true,
      },
      {
        title: "Touch POS Systems",
        contents: ["Give your business a 'touch' of brilliance! Our advanced yet, robust touch screen monitors ensure super quick billing operations while being absolutely easy to use."],
        productPath: "/products/touch-pos-systems",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems.png",
        isLightMode: false,
      },
      {
        title: "Thermal Receipt Printers",
        contents: ["Our best-selling Thermal Receipt Printers are a by-word in convenience. The printing systems have set industry-standards thanks to easy functionality, rapid printing, and unquestionable reliability. When the billing process is accelerated, long queues disappear!"],
        productPath: "/products/thermal-receipt-printers",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers.png",
        isLightMode: true,
      },
      {
        title: "Printers",
        contents: ["From Indian Railways to Banking institutions, the Dot Matrix Printer has repeatedly proven its trustworthiness. There is simply no equal when it comes to productivity & durability!"],
        productPath: "/products/printers",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers.png",
        isLightMode: false,
      },{
        title: "Label Printers",
        contents: ["Label printing operations have their own degrees of complexity. When it comes to high volume label printing which is consistent in quality and continuous, you can have no better partner than AASH'NIK."],
        productPath: "/products/label-printers",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers.png",
        isLightMode: true,
      },
      {
        title: "Keyboards and Mice",
        contents: ["The unique challenges posed by Indian conditions have led us to create the finest of keyboards & mice - ones where durability and immunity co-exist comfortably with operational ease and efficiency. Welcome to the Gold Class products!"],
        productPath: "/products/keyboards-and-mice",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice.png",
        isLightMode: false,
      },{
        title: "Currency Counter",
        contents: ["Loose notes? Check. Old and new currency? Check. Fake notes? Double check. And all of it in the blink of an eye. For businesses that thrive on high speed and heavy volumes, here's the perfect solution - Cash Counting Machines which bring together speed and precision with counterfeit detection and authentication. "],
        productPath: "/products/currency-counter",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter.png",
        isLightMode: true,
      },
      {
        title: "E-Cash Registers",
        contents: ["Thriving businesses deserve a reliable partner. E-cash Registers are tailor-made for businesses, thanks to its rugged build and efficient output. Designed to handle long working hours, our cash registers endure!"],
        productPath: "/products/e-cash-registers",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers.png",
        isLightMode: false,
      },{
        title: "Mobile Printers",
        contents: ["The smartest way to print labels & receipts. Compatible with tabs and mobiles."],
        productPath: "/products/mobile-printers",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers.png",
        isLightMode: true,
      },
      {
        title: "Cash Drawers",
        contents: ["Upgrade your point-of-sale (POS) system with our high-quality cash drawers, designed to streamline transactions and enhance security. Whether you're a retail store, restaurant, or any business handling cash transactions, our cash drawers are the perfect solution for keeping your cash organized and secure."],
        productPath: "/products/cash-drawers",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers.png",
        isLightMode: false,
      },{
        title: "Genuine Spares",
        contents: ["When it comes to maintaining the optimal performance of your printer, there's no substitute for genuine spares. Don't compromise on quality or risk damaging your valuable equipment with inferior parts. Invest in authenticity and reliability with our range of genuine spares."],
        productPath: "/products/genuine-spares",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/genuine_spares.png",
        isLightMode: true,
      },
      {
        title: "Laptops and Desktops",
        contents: ["Experience the power of productivity with our range of top-of-the-line laptops and desktops. Whether you're a professional on-the-go or need a powerhouse for your home office, we have the perfect solution to meet your needs."],
        productPath: "/contact",
        backgroundImage: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/laptops_and_desktops.png",
        isLightMode: false,
      }
    ]
  };
  
  export default ProductSummaryPageContent;