const individualProducts = {
    categories: [
        {
            id: "barcode-scanners",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners.png`,
            info: [
                {
                    id: "bs-l100-plus",
                    title: "BS-L100 PLUS",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-L100.png`,
                    description: "BS-L100 Plus is an affordable handheld Laser scanner that allows the user to easily target the desired bar code and provide complete data transmission by just triggering a button. This Laser scanner fits in various environments starting from small warehouses, offices, and retail shops.",
                    bullets: [
                        {
                            heaading: "ADVANTAGES",
                            data: [
                                "Reasonable cost and reliable option",
                                "Minimal divergence of Laser beam provides high Depth of Focus on Bar codes",
                                "Reads all standard 1D bar codes",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "PLUG AND PLAY DESIGN", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l100-plus/2.png`,
                        },
                        {
                          name: "POWERFUL SCANNING PERFORMANCE",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l100-plus/3.png`,
                        },
                        {
                          name: "1.5 M DROP RESISTANCE",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l100-plus/1.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Light Source",
                            value: "650 mm Laser",
                        },
                        {
                            type: "Supported Symbologies",
                            value: "EAN8, EAN13, UPCA, UPCE, CODE 39, CODE 93, CODE 128, EAN 128, Codabar, Industrial 2 of 5, Intrleave 2 of 5, Matrix 2 of 5, MSI and all 1D bar codes",
                        },
                        {
                            type: "Scan Type",
                            value: "Single Laser",
                        },
                        {
                            type: "Scan Speed",
                            value: "300 scans/sec",
                        },
                        {
                            type: "Scan Angle",
                            value: "Yaw 55°, Rotation 30°, Pitch 65°",
                        },
                        {
                            type: "Precision",
                            value: ">4 mil",
                        },
                        {
                            type: "Contrast",
                            value: ">20 %",
                        },
                        {
                            type: "Depth of Scan Field",
                            value: "0280 mm",
                        },
                        {
                            type: "Error rate",
                            value: "Less than 1/5 million",
                        },
                        {
                            type: "Interface",
                            value: "USB  ",
                        },
                        {
                            type: "Dimensions",
                            value: "90mm * 66mm * 173mm",
                        },
                        {
                            type: "Weight",
                            value: "129.5 g",
                        },
                        {
                            type: "Cable length",
                            value: "2 M",
                        },
                        {
                            type: "Operating Temperature",
                            value: "20°C~50°C",
                        },
                        {
                            type: "Relative Humidity",
                            value: "5%~95%",
                        },
                        {
                            type: "Lux",
                            value: "30008000 Lux",
                        },
                        {
                            type: "Drop/Shock resistance",
                            value: "Withstands a 1.5m drop from concrete",
                        },
                        {
                            type: "Voltage",
                            value: "DC 5V ± 1%",
                        },
                        {
                            type: "Operating Current",
                            value: "85 mA",
                        },
                        {
                            type: "IP Rating",
                            value: "IP 51",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l100-plus/downloads/brochure.pdf`,
                        },
                        {
                            name: "quick reference guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l100-plus/downloads/quick_ref_guide.pdf`,
                        },
                    ],
                },
                {
                    id: "bs-i201g-lite",
                    title: "BS i201G Lite",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-i201g-Lite.png`,
                    description: "BS-i201g Lite is a compact, lightweight, rugged entry-model 2D barcode scanner built for faster, reliable and efficient scanning performance. It is easy to use, has advanced scanning capabilities like 3-angle parameters – Pitch/Roll/Yaw, and scans lower reflective difference. It is ideal for various segments, like Healthcare, Supermarkets, Warehouses, Jewellery shops, Textile shops, Logistics, and Toll Plazas.",
                    bullets: [
                        {
                            heaading: "Advantages",
                            data: [
                                "High speed scan",
                                "Economical",
                                "High Print contrast",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "Speed upto 250 scans/sec", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201g-lite/1.png`,
                        },
                        {
                          name: "Print Contrast 20%",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201g-lite/2.png`,
                        },
                        {
                          name: "Roll/Pitch/Yaw 360°, ±65°, ±65°",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201g-lite/3.png`,
                        },
                        {
                          name: "Drop Resistance 1.2 m", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201g-lite/4.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Dimensions",
                            value: "160mm x 64mm x 97mm",
                        },
                        {
                            type: "Voltage",
                            value: "5V",
                        },
                        {
                            type: "Stand-by Current",
                            value: "145mA",
                        },
                        {
                            type: "Weight",
                            value: "140.5g",
                        },
                        {
                            type: "Scan angle",
                            value: "190mA",
                        },
                        {
                            type: "Work Current",
                            value: "380mA",
                        },
                        {
                            type: "Image (Pixels)",
                            value: "640 pixels (H) x 480 pixels (V)",
                        },
                        {
                            type: "Light Source",
                            value: "(Aimer) Red color LED; (Illumination) White color LED",
                        },
                        {
                            type: "Roll / Pitch / Yaw",
                            value: "360, ±65, ±65",
                        },
                        {
                            type: "Print Contrast",
                            value: "20% minimum reflective difference",
                        },
                        {
                            type: "Interfaces Supported",
                            value: "USB",
                        },
                        {
                            type: "Motion Tolerances",
                            value: ">0.4m/s",
                        },
                        {
                            type: "Symbology Decode Capability",
                            value: "1-D: Codabar, Code 39, Code 32 Pharmaceutical (PARAR, Interleaved 2 of 5, NEC2 of 5, Code 93, Straight 2 of 5 Industrial, Straight 2 of 5 IATA, Matrix 2 of 5, Code 11, Code 128, GS1-128, UPC-A, UPC-E, EAN/JAN-8, EAN/JAN-13, MSI, GS1 DataBar Omnidirectional, GS1 DataBar Limited, GS1 DataBar Expanded, China Post(Hong Kong 2 of 5), Korea Post. \n\n2-D: Codeblock A, Codeblock F, PDF417, Micro PDF417, GS1 Composite Codes, QR Code, Data Matrix, MaxiCode, Aztec, HANXIN. Symbology is turned on by default Codabar, Code39, Intefeaved2 of 5, NEC 2 Of 5, Code93, Code128, GS1-128, UPC-A, UPC-EO, EAN/JAN-13, EAN/JAN-8, GS1-DataBar, PDF417, QR, DM, Aztec.",
                        },
                        {
                            type: "Operating Temperature",
                            value: "-20°C to 60°C",
                        },
                        {
                            type: "Storage Temperature",
                            value: "30°C to 70°C",
                        },
                        {
                            type: "Humidity",
                            value: "5% to 95% relative humidity, non-condensing",
                        },
                        {
                            type: "Shock Specifications",
                            value: "Designed to withstand 1.2m(5′) drops",
                        },
                        {
                            type: "Ambient Light Immunit",
                            value: "0-100,000 Lux.",
                        },
                        {
                            type: "Code39(5mil)",
                            value: "20mm-110mm",
                        },
                        {
                            type: "Code39(13rnil)",
                            value: "30mm-250mm",
                        },
                        {
                            type: "Options",
                            value: "Stand",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201g-lite/downloads/brochure.pdf`,
                        }
                    ],
                },
                {
                    id: "bs-l150s-wl",
                    title: "BS-L150s WL",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150.png`,
                    description: "This lightweight scanner delivers superior performance and outstanding durability. It is an ideal solution for supermarkets, shopping malls, warehouses, and other environments.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "2.4 Ghz Wireless Laser Barcode Scanner",
                                "Offers accurate aiming and hassle free scanning",
                                "High storage ability of 4MB",
                                "Ergonomically designed for ease of use",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "1D Laser scanner with sharp aim and shoot reading", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150/1.png`,
                        },
                        {
                          name: "SCAN SPEED OF 150 SCANS PER SECOND",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150/3.png`,
                        },
                        {
                          name: "2.4Ghz wireless technology",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150/4.png`,
                        },
                        {
                          name: "Battery capacity of 2200 mAh", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150/2.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Interface",
                            value: "USB HID , 2.4 Ghz",
                        },
                        {
                            type: "Operating Freq.",
                            value: "2.4 Ghz",
                        },
                        {
                            type: "Light Source",
                            value: "650 nm Laser(safe visible laser diode)",
                        },
                        {
                            type: "Scan Rate",
                            value: ">150 scans/sec",
                        },
                        {
                            type: "Scan mode",
                            value: "Manual/Continuous/Automatic",
                        },
                        {
                            type: "Resolution",
                            value: "4 mil",
                        },
                        {
                            type: "Transmission Distance",
                            value: "50-80 M (open yard)",
                        },
                        {
                            type: "Depth of Field",
                            value: "10-250 mm (0.33 mm PCS 90%)",
                        },
                        {
                            type: "In-Built Memory",
                            value: "4MB",
                        },
                        {
                            type: "Barcode type support",
                            value: "All Standard 1D Barcodes",
                        },
                        {
                            type: "Battery Type",
                            value: "Lithium battery",
                        },
                        {
                            type: "Battery Capacity",
                            value: "2200 mAh",
                        },
                        {
                            type: "Working Current",
                            value: "135 mA",
                        },
                        {
                            type: "Standby Current",
                            value: "150uA",
                        },
                        {
                            type: "Input Voltage",
                            value: "DC5V±10%",
                        },
                        {
                            type: "Weight",
                            value: "242 gms",
                        },
                        {
                            type: "Housing Material",
                            value: "ABS+PC",
                        },
                        {
                            type: "Dimensions (LxWxH)",
                            value: "85*62*154mm",
                        },
                        {
                            type: "Operating Temperature",
                            value: "-20°C – 40°C",
                        },
                        {
                            type: "Storage Temperature",
                            value: "-40°C – 70°C",
                        },
                        {
                            type: "Operating Humidity",
                            value: "5% – 85% (Non-condensing)",
                        },
                        {
                            type: "Shock Resistance",
                            value: "1.5 m drop",
                        }
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150/downloads/brochure.pdf`,
                        },
                        {
                            name: "quick reference guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-l150/downloads/quick_ref_guide.pdf`,
                        },
                    ],
                },
                {
                    id: "bs-i201s-wl",
                    title: "BS-i201s WL",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-i201s-WL.png`,
                    description: "BS-i201s is a fast, reliable, and precise 2D Wireless Barcode Scanner with an ergonomic grip and rugged design. It is built with advanced imaging technology and a durable button for unmatched scanning performance. It has a resolution of 640*480 and supports 1D and 2D barcodes.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Decodes poorly printed or damaged 1D and 2D barcodes",
                                "Lightweight, rugged & handy ",
                                "Comfortable & durable scan button",
                                "Drop Shock Resistance of 3 meters on concrete",
                                "Improved offline & inventory storage modes",
                                "6 hours of battery backup",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "Economical 2D wireless scanner", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201s-wl/1.png`,
                        },
                        {
                          name: "40 meters line of sight – distance covered",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201s-wl/2.png`,
                        },
                        {
                          name: "512K characters storage",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201s-wl/3.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Light Source",
                            value: "617nm LED Aimer, White LED",
                        },
                        {
                            type: "Supported",
                            value: "1D: EAN, UPC, Code 39, Code 93, Code 128, UCC/EAN 128, Codabar, Interleave 2 of 5, ITF-6, ITF-4, ISBN, ISSN, MSI-Plessey, GS1 Databar, GS1 Composite Code, Code 11, Industrial 25, Standard 25, Plessey, Matrix 2 of 5. 2D: QR Code, Data Matrix, PDF417, Aztec, Maxicode, Micor QR, Micor PDF417, Hanxin Code.",
                        },
                        {
                            type: "Resolution",
                            value: "640*480",
                        },
                        {
                            type: "Scan mode",
                            value: "Manual/Continuous/Automatic Induction Scanning",
                        },
                        {
                            type: "Scan speed",
                            value: "150s/s",
                        },
                        {
                            type: "Scan angle",
                            value: "Yaw±55°, Rotation 360°, Pitch±55°",
                        },
                        {
                            type: "Precision",
                            value: "≥5mil",
                        },
                        {
                            type: "Contrast",
                            value: "≥20%",
                        },
                        {
                            type: "Depth of scan field",
                            value: "EAN13 50-200mm(13mil); QR 25-240mm(20mil); PDF417 30-130mm(6.67mil)",
                        },
                        {
                            type: "Error rate",
                            value: "1/5 million",
                        },
                        {
                            type: "Interface",
                            value: "USB-HID",
                        },
                        {
                            type: "Communication mode",
                            value: "Pairing dedicated receiver: 2.4G communication \n\nPairing Mobile Bluetooth Devices: Bluetooth BLE 4.0",
                        },
                        {
                            type: "Transmission distance",
                            value: "Offline storage Mode: When the scanner exits the connection range, the data is automatically stored.Storage capacity is 512000 characters. Inventory storage mode: Scanner can enter inventory mode, storage capacity is 512000 characters.",
                        },
                        {
                            type: "Pairing Mode",
                            value: "One to One: One Scanning Gun Pairs One Receiver;\n\nOne scanner paired with a Bluetooth mobile device",
                        },
                        {
                            type: "Operating temperature",
                            value: "-20°C~50°C",
                        },
                        {
                            type: "Storage temperature",
                            value: "-40°C~70°C",
                        },
                        {
                            type: "Relative humadity",
                            value: "5% ~ 95% RH (Non-condensing)",
                        },
                        {
                            type: "Dimensions",
                            value: "90mm*66mm*173mm",
                        },
                        {
                            type: "Weight",
                            value: "130g",
                        },
                        {
                            type: "Cable length",
                            value: "1.2m",
                        },
                        {
                            type: "Drop shock resistence",
                            value: "Withstands multiple times 3 meters drops to concrete",
                        },
                        {
                            type: "Material",
                            value: "ABS+PC",
                        },
                        {
                            type: "Voltage",
                            value: "DC5V±5%",
                        },
                        {
                            type: "Operating current",
                            value: "when not scanning code ≥26mA;",
                        },
                        {
                            type: "Battery working time",
                            value: "6h",
                        },
                        {
                            type: "Lithium battery",
                            value: "1400mA",
                        },
                        {
                            type: "Standby current",
                            value: "0mA(Standby is to cut off battery power)",
                        },
                        {
                            type: "Charging time",
                            value: "3.5h",
                        },
                        {
                            type: "Charge mode",
                            value: "Square USB charging cable, plug straight charge",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201s-wl/downloads/brochure.pdf`,
                        },
                        {
                            name: "installation guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i201s-wl/downloads/installation_guide.pdf`,
                        },
                    ],
                },
                {
                    id: "bs-i302-g",
                    title: "BS-i302 G",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/BS-i302g.png`,
                    description: "◎ \t Hands-Free Barcode reading for Retail Application\t\t\t\t\t\t\t\t\t \n◎ \t Production Line \n◎ \t Laboratories",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Best Motion capture",
                                "Best in class frames/sec rating",
                                "Easy to switch Auto Trigger and Manual mode",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "QUICK TO SWITCH MODE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/6.png`,
                        },
                        {
                          name: "SUPERIOR MOTION TOLERANCE",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/5.png`,
                        },
                        {
                          name: "IR TRIGGER",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/4.png`,
                        },
                        {
                          name: "READY TO READ ON SCREEN QR CODE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/1.png`,
                        },
                        {
                          name: "IP50 RATING SEATING",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/3.png`,
                        },
                        {
                            name: "1.2M DROP RESISTANT", 
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/2.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Image Sensor",
                            value: "752*480 CMOS",
                        },
                        {
                            type: "1 D",
                            value: "EAN13, EAN8, UPCA, UPCE, ISSN, ISBN, Codabar Code 128,Code 93, ITF6, ITF14, Interleaved 2 of 5 Industrial 2 of 5, Standard 2 of 5, Matrix 2 of 5, Gs1 Databar, Code 39, Code 11, MSIPlessy, Plessy, etc.",
                        },
                        {
                            type: "2 D",
                            value: "PDF417, QR Code, Data Matrix,Chinese Sensible Code",
                        },
                        {
                            type: "Resolution",
                            value: "6.67 mil (PDF 417), 5 mil (Code 39)",
                        },
                        {
                            type: "Code 39",
                            value: "40 200mm 20 mil",
                        },
                        {
                            type: "EAN13",
                            value: "5 180mm 13 mil",
                        },
                        {
                            type: "QR Code",
                            value: "10 180mm 20 mil",
                        },
                        {
                            type: "Motion Tolerance",
                            value: "2.5 m/s",
                        },
                        {
                            type: "Scan Angle",
                            value: "Pitch  ±60* @0* Roll and 0* Skew\nRoll    ±360* @0* Roll and 0* Skew\nSkew  ±50* @0* Roll and 0* Pitch",
                        },
                        {
                            type: "Symbol Contrast",
                            value: "=25%",
                        },
                        {
                            type: "Interface",
                            value: "USB",
                        },
                        {
                            type: "Dimension L x W x H",
                            value: "83 x 81 x 128 mm",
                        },
                        {
                            type: "Weight",
                            value: "29 g",
                        },
                        {
                            type: "Notification",
                            value: "Beep, LED indicator",
                        },
                        {
                            type: "Operating Current",
                            value: "150 mA",
                        },
                        {
                            type: "Standby Current",
                            value: "120 mA",
                        },
                        {
                            type: "Operating Temperature",
                            value: "20°C ~ 50°C",
                        },
                        {
                            type: "Storage Temperature",
                            value: "-40°C ~ 70°C ",
                        },
                        {
                            type: "Humidity",
                            value: "5% – 95% RH (non-condensing)",
                        },
                        {
                            type: "ESD",
                            value: "±14kv air discharge,±18kv direct discharge",
                        },
                        {
                            type: "Drop Durability",
                            value: "1.2m drop to concrete fr six side, one drop per side",
                        },
                        {
                            type: "Sealing",
                            value: "IP 50",
                        },
                        {
                            type: "Accessories",
                            value: "USB Cable Used to connect the scanner to a host device",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/downloads/brochure.pdf`,
                        },
                        {
                            name: "quick reference guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/barcode_scanners/bs-i302g/downloads/quick_ref_guide.pdf`,
                        },
                    ],
                }
            ],
        },
        {
            id: "touch-pos-systems",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems.png`,
            info: [
                {
                    id: "tp-415Ca",
                    title: "TP 415cA",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-415CA.png`,
                    description: "TP 415CA was android architecture Touch POS. It has build in WIFI & Bluetooth and die cast aluminium inner body.",
                    bullets: [
                        {
                            heaading: "ADVANTAGES",
                            data: [
                                "15″ Capacitive Multi Touch Display",
                                "Low Power Consumption",
                                "Android Architecture",
                                "Stylish and compact",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "15.6” Capacitive multi touch screen", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-415CA/1.png`,
                        },
                        {
                          name: "Built in WIFI & Bluetooth",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-415CA/2.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "CPU ",
                            value: "RK3288 Quad-Core A17, 1.8GHz",
                        },
                        {
                            type: "Memory",
                            value: "DDRIII 2GB",
                        },
                        {
                            type: "Storage",
                            value: "Flash storage 16GB",
                        },
                        {
                            type: "Display",
                            value: "15.6 Inch TFT LED Touch Screen 2 350-400cd/m",
                        },
                        {
                            type: "Display Resolution",
                            value: "Capacitive touch screen, resolution 1920 * 1080",
                        },
                        {
                            type: "Cash drawer(RJ11)",
                            value: "1 Pcs",
                        },
                        {
                            type: "Serial port / COM",
                            value: "1 Pcs RJ11",
                        },
                        {
                            type: "USB port",
                            value: "4 Pcs+1*Mirco USB 2.0",
                        },
                        {
                            type: "LAN port / RJ45",
                            value: "1 Pcs RJ45",
                        },
                        {
                            type: "TF Card",
                            value: "1 Pcs",
                        },
                        {
                            type: "DC input / output",
                            value: "1x DC 24V 1.5A",
                        },
                        {
                            type: "BT",
                            value: "Support",
                        },
                        {
                            type: "WIFI",
                            value: "Support",
                        },
                        {
                            type: "2nd Display(Optional)",
                            value: "10.1 Inch Second Display(1024×600)",
                        },
                        {
                            type: "OS support",
                            value: "Android 7.1",
                        },
                        {
                            type: "Certificates",
                            value: "CE, ROHS, FCC,CCC,ISO9001-2008",
                        },
                        {
                            type: "Operation Temperature",
                            value: "0°C – 50°C",
                        },
                        {
                            type: "Storage Temperature",
                            value: "-20°C – 60°C",
                        },
                        {
                            type: "Humidity",
                            value: "10%~90%",
                        },
                        {
                            type: "Power Supply",
                            value: "External Power Adapter",
                        },
                        {
                            type: "Color",
                            value: "White",
                        },
                        {
                            type: "Speaker",
                            value: "Yes",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-415CA/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "tp-482c-aio",
                    title: "TP 482c AIO",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO.png`,
                    description: "TP 482C AIO is a stylish, multi-functional billing device with 2-inch Thermal printer, Android operating system and optional peripherals. It is lightweight, compact and easy to operate. It is ideal for portability and convenient to use.",
                    bullets: [
                        {
                            heaading: "ADVANTAGES",
                            data: [
                                "Stylish and Compact Design",
                                "2\" Thermal Printer",
                                "Storage of 2GB+16GB / 4GB+32GB(optional)",
                                "Interface (3 USB, 1 Serial)",
                                "Prints 300 receipts continuously",
                                "Printing Speed 150mm/sec",
                                "Stand by for 10 hours",
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "Android", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO/2.png`,
                        },
                        {
                          name: "Capacitive Touch Screen",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO/3.png`,
                        },
                        {
                            name: "Multiple I/O interfaces: Ethernet, USB, RS232, cash drawer port, etc.",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO/1.png`,
                        },
                        {
                            name: "high-performance quad-core processor", 
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO/4.png`,
                        },
                        {
                            name: "Built-in 3200mAh lithium battery",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Processor ",
                            value: "RK3288/,main frequencyl.8GHz",
                        },
                        {
                            type: "Storage",
                            value: "2GB+16GB/4GB+32GB(optional) Android 7.1/8.1",
                        },
                        {
                            type: "Operating system Power supply",
                            value: "13.8V ::: 2.5A",
                        },
                        {
                            type: "Battery",
                            value: "7.4V, 3200mAh",
                        },
                        {
                            type: "Store display",
                            value: "8\" TFT (800’1280)",
                        },
                        {
                            type: "Touch screen",
                            value: "Capacitive",
                        },
                        {
                            type: "Customer display",
                            value: "65’132 dot matrix LCD",
                        },
                        {
                            type: "USB",
                            value: "3",
                        },
                        {
                            type: "Serial port",
                            value: "1",
                        },
                        {
                            type: "Cash drawer",
                            value: "1",
                        },
                        {
                            type: "Ethernet port",
                            value: "1 RJ45",
                        },
                        {
                            type: "SD card socket",
                            value: "1 MicroSD card socket",
                        },
                        {
                            type: "BT",
                            value: "V2.1-FEDR/BT v3.0+HS/BT v4.0",
                        },
                        {
                            type: "WIFI",
                            value: "IEEE 802.11b/g/n/d/e/h/i",
                        },
                        {
                            type: "4G (optional)",
                            value: "GSM: EGSM900MHz/DCS1800M Hz\nWCDMA: BAND1/8\nLTE-FDD: B1/B 3/B 5/B7/B8/20\nLTE-TDD: B38/B40",
                        },
                        {
                            type: "NFC (optional)",
                            value: "Support ISO/IEC1443Type A&B; Felica;\nMilare card(MIFARE Classic 1K/4K;MIFARE Ultralight;MIFARE Ultralight C;MIFARE PLUS & MI FARE DESFire)",
                        },
                        {
                            type: "Magnetic head (optional)",
                            value: "Support IS07811, three tracks two-way",
                        },
                        {
                            type: "Camera (optional)",
                            value: "5 mega-pixel, auto-focusing, support one dimensional code & qr code scanning",
                        },
                        {
                            type: "Scanner (optional)",
                            value: "CMOS",
                        },
                        {
                            type: "Thermal printer",
                            value: "2\" without cutter (57mmxCD5Omm)",
                        },
                        {
                            type: "Speed",
                            value: "150mm/s",
                        },
                        {
                            type: "Dimensions",
                            value: "279(L)*131mm(W)*100mm(H)",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-482C-AIO/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "tp-4103",
                    title: "TP 4103",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103.png`,
                    description: "Android SMART TERMINAL\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t",
                    features: [
                        {
                          name: "2GB-RAM", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/1.png`,
                        },
                        {
                          name: "Support",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/7.png`,
                        },
                        {
                            name: "80 mm Receipt printer", 
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/5.png`,
                        },
                        {
                            name: "64bit Octa-Core-CPU",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/4.png`,
                        },
                        {
                            name: "10-Inch",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/3.png`,
                        },
                        {
                            name: "5-in-1 multi card reader optional", 
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/2.png`,
                        },
                        {
                            name: "Support multi ports",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/6.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Operating System",
                            value: "Android 11",
                        },
                        {
                            type: "CPU",
                            value: "Octa Core, up to 2.0GHz",
                        },
                        {
                            type: "RAM",
                            value: "2GB",
                        },
                        {
                            type: "EMMC",
                            value: "16 GB",
                        },
                        {
                            type: "Main Screen",
                            value: "Size: 10.1 inch\nResolution: HD 800*1280  (FHD 1200*1920 Optional)\nBacklight: LED\nTouch Screen: Capacitive multipoint touch screen",
                        },
                        {
                            type: "Communication",
                            value: "Wi-Fi: 2.4GHz/SGHz IEEE802.11a/b/g/n/ac\nNetwork: 4G/3G/2G\nBluetooth: BLES.O\nGPS: GPS,BEIDOU,GLONASS",
                        },
                        {
                            type: "Card Reader",
                            value: "NFC, Magnetic and IC Chip",
                        },
                        {
                            type: "Printer",
                            value: "Thermal printer: Paper width 80mm",
                        },
                        {
                            type: "Camera",
                            value: "SMP (13MP Optional), support for barcode/qr code scanning",
                        },
                        {
                            type: "Fiscal module",
                            value: "Optional",
                        },
                        {
                            type: "Fingerprint module",
                            value: "Optional",
                        },
                        {
                            type: "Port",
                            value: "SIM x 2, PSAMx 2, Micro SD upto 128G B, USB Type A x 4, USB Type C x 1, DC Port 12V 3A, RJ12 x 1, RJ11 x 1, RJ45 x 1, Mini USB x 1",
                        },
                        {
                            type: "Microphone",
                            value: "Yes",
                        },
                        {
                            type: "Speaker",
                            value: "Yes",
                        },
                        {
                            type: "Physical button",
                            value: "x 1, power button",
                        },
                        {
                            type: "Power adapter",
                            value: "Input: 10V to 240V; AC Output: 12V DC / 3A",
                        },
                        {
                            type: "Standard Accessories",
                            value: "Power adapter x 1, User manual x 1, Paper roll x 1",
                        },
                        {
                            type: "Dimension and Weight",
                            value: "Machine Size: 262 x 263.5 x 91.5mm, 1.45kg/pc\n Package size: 35x15x39cm, 3.5kg/pc",
                        },
                        {
                            type: "Software Configuration",
                            value: "Language: Support multi-language,default in English\nAudio format: MP3,MP2,AMR, AWB, APE, M4A, MIDI, OGG,WAV, FLAC, 3GP\nVideo format: MPEG4-SP,  H.264/AVC,H.263,3GP, 3G2,MP4,MKV,AV I,WEBM\nPicture format: JPEG, BMP, PNG,GIF\nE-book format: TXT,PDF, FB2,EPUB",
                        },
                        {
                            type: "Temperature",
                            value: "Storage temperature:-10°C - 60°C\nWorking Temperature: 0°C - 40°C",
                        },
                        
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/touch_pos_systems/TP-4103/downloads/brochure.pdf`,
                        },
                    ],
                }
            ],
        },
        {
            id: "thermal-receipt-printers",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers.png`,
            info: [
                {
                    id: "rp-3220-star",
                    title: "RP 3220 STAR",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR.png`,
                    description: "RP 3220 Star thermal printer comes with different wired and wireless options such as Serial, Ethernet, and wifi, which makes it an exemplary choice for Retail and Hospitality segments. Its High-speed printing enhances swift billing and ticketing, thus reducing long queues.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Compact Design with effective floor space utilization",
                                "Top and Front exit",
                                "Wall Mountable",
                                "Easy Paper load with Jam free design"
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "3-INCH / 80 MM PAPER WIDTH", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/1.png`,
                        },
                        {
                          name: "2 MB FLASH MEMORY",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/2.png`,
                        },
                        {
                          name: "EASY PAPER LOAD & JAM FREE DESIGN",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/3.png`,
                        },
                        {
                          name: "200 MM/S PRINT SPEED", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/4.png`,
                        },
                        {
                          name: "180 DPI RESOLUTION",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print Model ",
                            value: "RP 3220 STAR",
                        },
                        {
                            type: "Printing Method",
                            value: "Direct Thermal-line printing",
                        },
                        {
                            type: "Resolution",
                            value: "180 DPI",
                        },
                        {
                            type: "Max. Print Speed",
                            value: "220 mm 8.7\"/second",
                        },
                        {
                            type: "Max. Print Width",
                            value: "72 mm 2.8\"",
                        },
                        {
                            type: "BARCODE 1D",
                            value: "Code 11, Code 39, Code 93, Codabar, ISBT128, UPCA, UPCE, EAN8, EAN13, Code 128 with subsets A /B /C, Interleaved 2 of 5",
                        },
                        {
                            type: "BARCODE 2D",
                            value: "PDF417, QR Code, DATA MATRIX",
                        },
                        {
                            type: "Flash Memory",
                            value: "2 M",
                        },
                        {
                            type: "Paper Width",
                            value: "80 mm",
                        },
                        {
                            type: "Paper Thickness",
                            value: "0.065 mm ",
                        },
                        {
                            type: "Paper Roll Diameter",
                            value: "83 mm",
                        },
                        {
                            type: "Interface Standard",
                            value: "USB B-type + Serial RS232C",
                        },
                        {
                            type: "Interface Option",
                            value: "USB B-type + Ethernet\nUSB B-type + serial + Wifi or Bluetooth",
                        },
                        {
                            type: "Power Type",
                            value: "External SMPS",
                        },
                        {
                            type: "Power AC",
                            value: "100 ~ 240Vac 50/60 Hz",
                        },
                        {
                            type: "Power DC",
                            value: "24Vdc / 1.75AMAX 83 mm",
                        },
                        {
                            type: "Sensor",
                            value: "Paper End, Cover Open",
                        },
                        {
                            type: "Print Head Life",
                            value: "150 Km",
                        },
                        {
                            type: "Auto Cutter",
                            value: "1,500,000 Cuts guillotine",
                        },
                        {
                            type: "Cash Drawer",
                            value: "DKD Cash Drawer 1 Port",
                        },
                        {
                            type: "Emulation",
                            value: "ESC/POS Command compatible",
                        },
                        {
                            type: "Accessories",
                            value: "Wall mount",
                        },
                        {
                            type: "Dimensions",
                            value: "130×140×154 mm W * D * H",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/brochure.pdf`,
                        },
                        {
                            name: "user manual aue",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/user_manual_aue.pdf`,
                        },
                        {
                            name: "user manual aus",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/user_manual_aus.pdf`,
                        },
                        {
                            name: "Java POSADK 1.82",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/apps/JavaPOSADK1.82.zip`,
                        },
                        {
                            name: "Linux CUPS Driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/apps/Linux-CUPS-Driver.zip`,
                        },
                        {
                            name: "Memory Saver 2.51",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/aps/MemorySaver_2.51.zip`,
                        },
                        {
                            name: "OLE POS Setup 2.85",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/apps/OLE-POS-Setup-2.85.zip`,
                        },
                        {
                            name: "Printer Drivers",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3220-STAR/downloads/apps/RP-3220-Star-printer-drivers.zip`,
                        },
                    ],
                },
                {
                    id: "rp-3200-plus",
                    title: "RP 3200 PLUS",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-plus.png`,
                    description: "This printer is a definite plus for retail business as it accelerates the billing process, by reducing transaction time and cutting back on long queues.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Offers High speed printing of 200 mm/sec",
                                "Triple interface: USB, Serial and Ethernet",
                                "203 DPI resolution",
                                "Compact Design, occupying small footprint",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "HIGH SPEED PRINTING OF 200 mm/sec", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/4.png`,
                        },
                        {
                          name: "TRIPLE INTERFACE : USB, SERIAL and ETHERNET",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/1.png`,
                        },
                        {
                          name: "203 DPI RESOLUTION",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/3.png`,
                        },
                        {
                          name: "COMPACT DESIGN, OCCUPYING SMALL FOOTPRINT", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/2.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print Model ",
                            value: "RP 3200 Plus",
                        },
                        {
                            type: "Printing Method",
                            value: "Direct Thermal Line Printing",
                        },
                        {
                            type: "Resolution",
                            value: "203 DPI",
                        },
                        {
                            type: "Print Width",
                            value: "Max 72mm",
                        },
                        {
                            type: "Print Speed",
                            value: "Max 200 mm/sec",
                        },
                        {
                            type: "Memory",
                            value: "4MB Flash",
                        },
                        {
                            type: "Sensor",
                            value: "Paper End & Cover Open",
                        },
                        {
                            type: "Cash Drawer Control",
                            value: "1 Cash Drawer",
                        },
                        {
                            type: "Interface",
                            value: "USB, Serial, Ethernet",
                        },
                        {
                            type: "Barcodes",
                            value: "JAN8 (EAN8), JAN13 (EAN13), ITFUPC-A, UPC-E, CODE 39, CODE 93, CODE 128, CODABAR, PDF 417",
                        },
                        {
                            type: "Character set",
                            value: "USA, France, Germany, UK, Denmark I, Denmark II, Italy, Sweden, Spain I, Spain II, Japan, Norway, Latin America, Korea, Japanese, Chinese, GB18030, Gb2312",
                        },
                        {
                            type: "Fonts",
                            value: "Font A(12*24), Font B(9*17), Kanji Font(24*24)",
                        },
                        {
                            type: "Paper Type",
                            value: "Continuous Paper, Marked Paper",
                        },
                        {
                            type: "Paper thickness",
                            value: "0.06mm-0.08mm",
                        },
                        {
                            type: "Paper roll width",
                            value: "80±0.5mm",
                        },
                        {
                            type: "Paper cut",
                            value: "Full cut, partial cut, tear off",
                        },
                        {
                            type: "Input",
                            value: "AC 100-240 V, 50/60Hz",
                        },
                        {
                            type: "Output",
                            value: "24V, 2.5A",
                        },
                        {
                            type: "Print Head Life time",
                            value: "100km",
                        },
                        {
                            type: "Cutter life time",
                            value: "1,000,000 cuts",
                        },
                        {
                            type: "Operation condition",
                            value: "5~45°C, 20~90% RH (40°C)",
                        },
                        {
                            type: "Storage condition",
                            value: "~-40°C – 60°C 20%~93%RH (40°C)",
                        },
                        {
                            type: "Dimension",
                            value: "126(W)×160.5(L)×116.5(H)mm",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/downloads/brochure.pdf`,
                        },
                        {
                            name: "OPOS",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/downloads/apps/OPOS.zip`,
                        },
                        {
                            name: "Printer Utility WIN_V1.1.2.0",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/downloads/apps/PrinterUtility_WIN_V1.1.2.0.zip`,
                        },
                        {
                            name: "Linux Driver EN V1.0",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/downloads/apps/RP3200_plus_LinuxDrv_EN_V1.0.zip`,
                        },
                        {
                            name: "Setup V3.0",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3200-Plus/downloads/apps/Setup_RP3200_plus_V3.0.zip`,
                        },
                    ],
                },
                {
                    id: "rp-3230",
                    title: "RP 3230",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230.png`,
                    description: "RP 3230 thermal printer comes with different wired options such as USB, Serial, Ethernet and cashdrawer port which makes it an exemplary choice for Retail and Hospitality segments. Its High-speed printing enhances swift billing and ticketing, thus reducing long queues.Equipped with three at a glance LEDs for visualizing printer status.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Compact design ",
                                "Luminous logo design",
                                "High printing speed",
                                "Multiple Communication Interface",
                                "Compatible with commands Supports advanced emulations",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "Compact and Ergonomically Designed", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/1.png`,
                        },
                        {
                          name: "Print speed upto 230mm/s with minimal noise",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/2.png`,
                        },
                        {
                          name: "Three at-a-glance LEDs for visualizing printer status",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/5.png`,
                        },
                        {
                          name: "Type-B USB 2.0, Ethernet, RS232, Cash drawer", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/6.png`,
                        },
                        {
                          name: "Support wall mounting",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/4.png`,
                        },
                        {
                            name: "Support error reprint",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/3.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print Model ",
                            value: "RP 3230",
                        },
                        {
                            type: "Printing Method",
                            value: "Direct thermal printing",
                        },
                        {
                            type: "Resolution",
                            value: "203 DPI (8 dots/mm)",
                        },
                        {
                            type: "Print Width",
                            value: "72mm / 48mm",
                        },
                        {
                            type: "Print Speed",
                            value: "Max. 230mm/s",
                        },
                        {
                            type: "Emulation",
                            value: "ESC/POS",
                        },
                        {
                            type: "Page Mode",
                            value: "Support",
                        },
                        {
                            type: "Page Type",
                            value: "Thermal continuous paper",
                        },
                        {
                            type: "Paper Width",
                            value: "79.5±0.5mm/57.5±0.5mm",
                        },
                        {
                            type: "Paper Container",
                            value: "Max. 83mm",
                        },
                        {
                            type: "Paper Feed",
                            value: "Upward feed direction",
                        },
                        {
                            type: "Standard",
                            value: "USB Type-B, Ethernet, Serial, Cash drawer",
                        },
                        {
                            type: "1D barcode",
                            value: "UPC-A, UPC-E, Code 39, Code 93, Code] 28, EAN8, EANI3, Interleaved 2 of 5, Codabar",
                        },
                        {
                            type: "2D barcode",
                            value: "QR Code, PDF417",
                        },
                        {
                            type: "Chinese Character",
                            value: "Simplified/Traditional: (24 x 24 dots)",
                        },
                        {
                            type: "English Character",
                            value: "ASCII: Font A 12×24, Font B 9×17",
                        },
                        {
                            type: "Code Page",
                            value: "47",
                        },
                        {
                            type: "Graphics",
                            value: "Support bitmap printing and bitmap downloading with different density and user defined (Max. 64K for each bitmap and the total bitmap cannot exceed 512K)",
                        },
                        {
                            type: "Buzzer",
                            value: "Internal buzzer kitchen bell(optional)",
                        },
                        {
                            type: "Sensor (Standard)",
                            value: "Paper end, cover opening, cutter jam, TPH over-temperature",
                        },
                        {
                            type: "Sensor (Optional)",
                            value: "Black mark sensor",
                        },
                        {
                            type: "RAM",
                            value: "4 MB",
                        },
                        {
                            type: "Flash",
                            value: "4 MB",
                        },
                        {
                            type: "Input Power",
                            value: "AC 100V-240V, 50/60Hz",
                        },
                        {
                            type: "Output Power",
                            value: "DC 24V 2A",
                        },
                        {
                            type: "Driver",
                            value: "Windows 7/8/10, Windows Server 2008/2012/2016, Linux, Javapos, OPOS, MacOS",
                        },
                        {
                            type: "Utility Tool",
                            value: "Windows 7/8/10, Windows Server 2008/2012/2016",
                        },
                        {
                            type: "TPH Life",
                            value: "100 km",
                        },
                        {
                            type: "Cutter Life",
                            value: "1,500,000 times",
                        },
                        {
                            type: "Operating",
                            value: "0°C-45°C / 5-95%RH No condensation",
                        },
                        {
                            type: "Storage",
                            value: "20°C-70°C / I5-95%RH No condensation",
                        },
                        {
                            type: "Certification",
                            value: "BIS",
                        },
                        {
                            type: "Weight",
                            value: "0.916kg",
                        },
                        {
                            type: "Dimension",
                            value: "140×179×117 (L×W×H (mm))",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/downloads/brochure.pdf`,
                        },
                        {
                            name: "installation guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/downloads/installation_guide.pdf`,
                        },
                        {
                            name: "Drivers",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/thermal_receipt_printers/RP-3230/downloads/drivers.zip`,
                        },
                    ],
                },
            ],
        },
        {
            id: "printers",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers.png`,
            info: [
                {
                    id: "msp-270-classic-plus",
                    title: "MSP 270 Classic Plus",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus.png`,
                    description: "The MSP 270 Classic Plus is a robust dot matrix printer tailored for high-volume printing needs across various sectors such as banking, retail, logistics, and government offices. With its reliability, cost-efficiency, and robust performance, it has become a go-to solution for businesses needing fast and high-quality document generation. It delivers a combination of speed, dependability, and versatility, making it a preferred choice for environments that demand efficient and precise document printing.",
                    features: [
                        {
                          name: "Printing Speed 270 CPS @ 10 CPI / 375 CPS @ 15 CPI", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/1.png`,
                        },
                        {
                          name: "Reliability: 15000 Power On hours",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/2.png`,
                        },
                        {
                          name: "Interfaces: Parallel/ USB/ Serial (Optional)",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/3.png`,
                        },
                        {
                          name: "Enhanced print head life", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/4.png`,
                        },
                        {
                          name: "iSense – built-in self-diagnostic tool",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/5.png`,
                        },
                        {
                            name: "Wide Operating Voltage: 90V-270V AC",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/6.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print Method",
                            value: "Serial Impact Dot Matrix",
                        },
                        {
                            type: "Number of Pins in Head",
                            value: "9 pins",
                        },
                        {
                            type: "Print Direction",
                            value: "Bi-directional",
                        },
                        {
                            type: "Control Code",
                            value: "ESC / P, IBM Proprinter",
                        },
                        {
                            type: "Print Width",
                            value: "80 Column",
                        },
                        {
                            type: "Print Head Life",
                            value: "200 Plus Million Characters",
                        },
                        {
                            type: "Print Speed",
                            value: "High Speed Draft: 10 / 12 / 15 cpi 350/ 360 / 400 cps;\nDraft: 10 /12 /15 cpi 270 / 320 / 375 cps;\nNLQ: 10 / 12 / 15 65 /75 /75 cps;",
                        },
                        {
                            type: "Print Characteristics",
                            value: "Character Sets Resident Printer font;\nDraft;\nNear Letter Quality: Roman, Sans Serif, Script;\nResident Barcode Fonts: Code 3 of 9\n",
                        },
                        {
                            type: "Pitch",
                            value: "10/ 12/ 15/ 17/ 20 cpi\n80/ 96/ 120/ 137/ 160 cpl",
                        },
                        {
                            type: "Paper Handling",
                            value: "Paper Path Tractor / Manual: Top, rear and bottom;\nContinuous Tractor: Convertible push and pull;\nCut Sheet (Single Sheet): 3.5 -10\";\nCut Sheet Width: 3.5 -10\";\nPaper Thickness: 0.3 mm;\nContinuous Paper: 3.5 -10\";\nRoll Paper",
                        },
                        {
                            type: "Paper Feeding",
                            value: "Standard: Friction, Push Tractor;\nCopies: 1+3 with carbon;"
                        },
                        {
                            type: "Line Spacing",
                            value: "4.23mm (1/6\"\") or programmable in increments of 0.118mm (1/216\"\")",
                        },
                        {
                            type: "Input Data Buffer",
                            value: "100 KB",
                        },
                        {
                            type: "Interface",
                            value: "Bi-directional parallel\n(IEEE-1284) nibble mode suported\nUSB 2.0 Full-Speed Serial (Optional)",
                        },
                        {
                            type: "Ribbon Cartridge",
                            value: "Standard: Patented TVS-E ribbon cassette with user replaceable Ink bank",
                        },
                        {
                            type: "Mean Time Between Failure (MTBF)",
                            value: "15000 POH",
                        },
                        {
                            type: "Acoustics",
                            value: "55 dB (A) in ISO 7779 Sonic Pattern",
                        },
                        {
                            type: "Emulation",
                            value: "EPSON ESC/P, IBM Proprinter",
                        },
                        {
                            type: "Control Panel",
                            value: "4 switches and 5 LEDs",
                        },
                        {
                            type: "Operating Temperature",
                            value: "(+5°C to +45°C)",
                        },
                        {
                            type: "Operating Humidity",
                            value: "10% to 80%",
                        },
                        {
                            type: "Rated Voltage",
                            value: "90V – 270V AC",
                        },
                        {
                            type: "Rated Frequency",
                            value: "47 - 63 Hz",
                        },
                        {
                            type: "Power Consumption",
                            value: "Operating: 34W – Self test\nStandard by: 12 W",
                        },
                        {
                            type: "Operating System",
                            value: "Windows 9X, NT 4.0, ME, 2000, 2003, XP, VISTA, Windows 7, Linux",
                        },
                        {
                            type: "Electronic Guide",
                            value: "HTML Based",
                        },
                        {
                            type: "Size (WxDxH)",
                            value: "433x379x147 mm",
                        },
                        {
                            type: "Weight",
                            value: "5.7 kg",
                        },
                        {
                            type: "Warranty",
                            value: "1 year",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/downloads/brochure.pdf`,
                        },
                        {
                            name: "Driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/MSP-270-Classic-Plus/downloads/driver.zip`,
                        },
                    ],
                },
                {
                    id: "rp-45-shoppe-bt",
                    title: "RP 45 Shoppe BT",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT.png`,
                    description: "The printer is a high performance printer with POS DMP which ensures high printing speed, making it the best choice for heavy duty data processing with intensive usage",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Great print speed supports faster transactional support",
                                "Simple, user-friendly & extremely easy to operate",
                                "Perfect companion for rough and rugged terrains",
                                "Can withstand voltage fluctuations and durable for all terrains",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "Compact & user-friendly", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/1.png`,
                        },
                        {
                          name: "Faster printing Speed – 5Lps",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/2.png`,
                        },
                        {
                          name: "Low operational Costs",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/3.png`,
                        },
                        {
                          name: "Stationery-friendly and convenient", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/4.png`,
                        },
                        {
                          name: "Trouble-free Operations",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/5.png`,
                        },
                        {
                            name: "USB+Parallel",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/6.png`,
                        },
                        {
                            name: "bluetooth", 
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/7.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print method",
                            value: "9-pin serial impact dot matrix",
                        },
                        {
                            type: "Printing Speed",
                            value: "5 lps @ 16 cpi; 40 col",
                        },
                        {
                            type: "Print Font",
                            value: "7 * 9",
                        },
                        {
                            type: "Print width",
                            value: "2.25\" – 3.9\"",
                        },
                        {
                            type: "Character sets",
                            value: "95 Alphanumeric characters",
                        },
                        {
                            type: "Barcode font",
                            value: "EAN 13, EAN 8, Code 3 of 9, Interleaved 2 of 5, UPC-A",
                        },
                        {
                            type: "Character Per Inch",
                            value: "Draft: 12, 13.3, 15, 16, 17, 20;\nHigh speed draft: 12;\nNLQ: 12;",
                        },
                        {
                            type: "Interface",
                            value: "USB, Parallel, Serial",
                        },
                        {
                            type: "Cash Drawer Support",
                            value: "1 port 9 (RJ 11 Socket)",
                        },
                        {
                            type: "Copy Capablity",
                            value: "1 original + 1 copy",
                        },
                        {
                            type: "Roll Paper Dimensions",
                            value: "Width: 57 mm to 105 mm (2.25″ to 4.13″);\nDiameter: 83 mm Max;\nCore inner diameter: 10 mm min;\nPaper tear: Stainless Steel tear bear;\nInput Buffer Capacity: 10 KB",
                        },
                        {
                            type: "Ribbon Cassette",
                            value: "Colour: Black;\nLife: 15 Mn Characters in Draft mode with 2 additional inkbanks;\nRefill mode: Through TVS Inkbank;\nInkbank life: 5 million characters in draft mode;\nEmulation: ESC / POS, Esc P, IBM;\nInput Power Voltage: 25 V DC & 5 V DC;\nPower Supply: External PSU",
                        },
                        {
                            type: "Mechanism",
                            value: "20 Million Lines MCBF",
                        },
                        {
                            type: "MTBF",
                            value: "10,000 POH @ 33% print density",
                        },
                        {
                            type: "Print Head",
                            value: "PTFE Coated, 200 Mn Characters in Draft",
                        },
                        {
                            type: "Drivers",
                            value: "Windows 95/98/ME/2000/NT4.0/XP, Vista, Linux",
                        },
                        {
                            type: "Physical Dimensions(mm)",
                            value: "185(W) * 260(D) * 135(H)",
                        },
                        {
                            type: "Weight",
                            value: "Approx 2.5 Kg",
                        },
                        {
                            type: "Colour",
                            value: "Bone Grey, Black",
                        },
                        {
                            type: "Noise Level",
                            value: "49 dB (A)",
                        },
                        {
                            type: "Safety / EMC standard",
                            value: "FCC Class B, CE compliant, IEC 950",
                        },
                        {
                            type: "Standard Configuration",
                            value: "Printer TVS Ribbon Cassette External Power Adapter Parallel\nUSB interface cable printer software CD-ROM user manual",
                        },
                        {
                            type: "Consumables",
                            value: "Ribbon Cassette, Inkbank",
                        },
                        {
                            type: "Accessories",
                            value: "Cash drawer cable",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/downloads/brochure.pdf`,
                        },
                        {
                            name: "driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE-BT/downloads/driver.zip`,
                        },
                    ],
                },
                {
                    id: "rp-45-shoppe",
                    title: "RP 45 Shoppe",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE.png`,
                    description: "The printer is a high performance printer with POS DMP which ensures high printing speed, making it the best choice for heavy duty data processing with intensive usage",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Great print speed supports faster transactional support",
                                "Simple, user-friendly & extremely easy to operate",
                                "Perfect companion for rough and rugged terrains",
                                "Can withstand voltage fluctuations and durable for all terrains",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "4-IN-ONE CONNECTIVITY", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/1.png`,
                        },
                        {
                          name: "COMPACT & USER-FRIENDLY",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/2.png`,
                        },
                        {
                          name: "FASTER PRINTING SPEED",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/3.png`,
                        },
                        {
                          name: "LOW OPERATIONAL COSTS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/4.png`,
                        },
                        {
                          name: "STATIONERY FRIENDLY AND CONVENIENT",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/5.png`,
                        },
                        {
                            name: "TROUBLE-FREE OPERATIONS",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/6.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print method",
                            value: "9-pin serial impact dot matrix",
                        },
                        {
                            type: "Printing Speed",
                            value: "5 lps @ 16 cpi; 40 col",
                        },
                        {
                            type: "Print Font",
                            value: "7 * 9",
                        },
                        {
                            type: "Print width",
                            value: "2.25\" – 3.9\"",
                        },
                        {
                            type: "Character sets",
                            value: "95 Alphanumeric characters",
                        },
                        {
                            type: "Barcode font",
                            value: "EAN 13, EAN 8, Code 3 of 9, Interleaved 2 of 5, UPC-A",
                        },
                        {
                            type: "Character Per Inch",
                            value: "Draft: 12, 13.3, 15, 16, 17, 20;\nHigh speed draft: 12;\nNLQ: 12;",
                        },
                        {
                            type: "Interface",
                            value: "USB, Parallel, Serial",
                        },
                        {
                            type: "Cash Drawer Support",
                            value: "1 port 9 (RJ 11 Socket)",
                        },
                        {
                            type: "Copy Capablity",
                            value: "1 original + 1 copy",
                        },
                        {
                            type: "Roll Paper Dimensions",
                            value: "Width: 57 mm to 105 mm (2.25″ to 4.13″);\nDiameter: 83 mm Max;\nCore inner diameter: 10 mm min;\nPaper tear: Stainless Steel tear bear;\nInput Buffer Capacity: 10 KB",
                        },
                        {
                            type: "Ribbon Cassette",
                            value: "Colour: Black;\nLife: 15 Mn Characters in Draft mode with 2 additional inkbanks;\nRefill mode: Through TVS Inkbank;\nInkbank life: 5 million characters in draft mode;\nEmulation: ESC / POS, Esc P, IBM;\nInput Power Voltage: 25 V DC & 5 V DC;\nPower Supply: External PSU",
                        },
                        {
                            type: "Mechanism",
                            value: "20 Million Lines MCBF",
                        },
                        {
                            type: "MTBF",
                            value: "10,000 POH @ 33% print density",
                        },
                        {
                            type: "Print Head",
                            value: "PTFE Coated, 200 Mn Characters in Draft",
                        },
                        {
                            type: "Drivers",
                            value: "Windows 95/98/ME/2000/NT4.0/XP, Vista, Linux",
                        },
                        {
                            type: "Physical Dimensions(mm)",
                            value: "185(W) * 260(D) * 135(H)",
                        },
                        {
                            type: "Weight",
                            value: "Approx 2.5 Kg",
                        },
                        {
                            type: "Colour",
                            value: "Bone Grey, Black",
                        },
                        {
                            type: "Noise Level",
                            value: "49 dB (A)",
                        },
                        {
                            type: "Safety / EMC standard",
                            value: "FCC Class B, CE compliant, IEC 950",
                        },
                        {
                            type: "Standard Configuration",
                            value: "Printer TVS Ribbon Cassette External Power Adapter Parallel\nUSB interface cable printer software CD-ROM user manual",
                        },
                        {
                            type: "Consumables",
                            value: "Ribbon Cassette, Inkbank",
                        },
                        {
                            type: "Accessories",
                            value: "Cash drawer cable",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/RP-45-SHOPPE/downloads/brochure.pdf`,
                        },
                        {
                            name: "driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers//downloads/driver.zip`,
                        },
                    ],
                },
                {
                    id: "speed-40-plus",
                    title: "SPEED 40 PLUS",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS.png`,
                    description: "High Speed High Performance Passbook Printer. 24 Wire, 94 Column Heavy duty Passbook printer with high speed of 580 cps.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Reliable, Fast and Powerful 580 CPS Speed.",
                                "Choice of Interface USB / Parallel / Serial.",
                                "Supports Passbooks – Vertical or Horizontal",
                                "Print Head life: 400 Million Characters",
                                "MTBF: 10000 POH.",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "CHOICE OF INTERFACE – USB / SERIAL", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/1.png`,
                        },
                        {
                          name: "MTBF: 10,000 POH",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/2.png`,
                        },
                        {
                          name: "PRINT HEAD LIFE: 400 MILLION CHARACTERS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/4.png`,
                        },
                        {
                          name: "SUPERIOR RELIABLITY", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/3.png`,
                        },
                        {
                          name: "SUPPORTS PASSBOOK – VERTICAL / HORIZONTAL",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Printing Technology",
                            value: "Impact Dot Matrix",
                        },
                        {
                            type: "Printing Speed",
                            value: "580cps (VHSD), 520cps (HSD), 400cps (Draft), 200cps (NLQ), 133cps (LQ)",
                        },
                        {
                            type: "Line length",
                            value: "94 columns @ 10cpi, 112 columns @ 12cpi, 141 columns @ 15cpi",
                        },
                        {
                            type: "Paper Handling",
                            value: "Single sheet, envelopes, label,\nfanfold (40 gr/m2 to 200 gr/m2 – 0.65mm to 2.7mm),\nPassbook (horizontal and vertical),\nSingle sheet width 65- 244 mm, length 65- 470mm",
                        },
                        {
                            type: "Copies",
                            value: "1 original + 6 copies",
                        },
                        {
                            type: "Special Functions",
                            value: "Automatic Gap Adjustment(AGA),\nDocument Auto Alignment, Auto Border Recognition,\nOptical Mark Reading, Automatic Set-up.",
                        },
                        {
                            type: "Display(optional)",
                            value: "LCD display / 2 lines with 16 characters each",
                        },
                        {
                            type: "Graphic Resolution",
                            value: "60, 120, 180, 240, 360 horizontal\n72, 90, 180, 216, 360 vertical",
                        },
                        {
                            type: "Character Set",
                            value: "21 International Code Page, 28 International Character Set",
                        },
                        {
                            type: "Barcodes",
                            value: "UPC A/E, EAN 8/13, Code 11/39/93/ 128, ADD-ON 2/5, BCD, MSI, 2/5 Interleaved, 2/5 Matrix, 2/5 Industrial, Postnet, Codabar",
                        },
                        {
                            type: "Resident Font",
                            value: "Draft, Courier, Gothic, Prestige, Presentor, Script, OCR-A/B, Boldface",
                        },
                        {
                            type: "Resident Emulations",
                            value: "IBM Personal Printer 2390+, Proprinter XL24-XL24AGM, Epson Lq2550, IBM 4722, Olivetti PR40plus / PR2 / PR2845, IBM 9068, HPR 4915",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/downloads/brochure.pdf`,
                        },
                        {
                            name: "driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-PLUS/downloads/apps/Speed-40-Plus-Windows-driver.zip`,
                        },
                    ],
                },
                {
                    id: "speed-40-star",
                    title: "SPEED 40 STAR",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR.png`,
                    description: "High Speed High Performance Passbook Printer with 94 columns @ 10cpi , 112 columns @12cpi",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Most productive & compact size",
                                "Choice of Interface USB / Serial",
                                "Auto alignment function for operator's convenience",
                                "Bi-directional printing method with logical seeking",
                                "High quality printing with 24 pins",
                                "Print Head life: 400 Million Characters",
                                "MTBF: 10000 POH",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "Choice of Interface USB / Serial", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/3.png`,
                        },
                        {
                          name: "Print Head life 400 Million Characters",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/5.png`,
                        },
                        {
                          name: "MTBF – 10000 POH",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/4.png`,
                        },
                        {
                          name: "Bi-directional printing method with logical seeking", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/2.png`,
                        },
                        {
                          name: "Auto alignment function for operator's convenience",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/1.png`,
                        },
                        {
                            name: "Synergistic key solution for fulfillment of the user's complicated requirements at working area",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/6.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Printing Technology",
                            value: "24pin, Impact Dot matrix",
                        },
                        {
                            type: "Print Speed@10cpi",
                            value: "VHSD : 560, HSD : 510, Draft : 370, NLQ: 185, LQ:125",
                        },
                        {
                            type: "CPI supported",
                            value: "10,12,15, 16.6, 17.1, 20 CPI and proportional font",
                        },
                        {
                            type: "Passbook Thickness",
                            value: "Thickness up to 2.6 mm",
                        },
                        {
                            type: "Line Length",
                            value: "94 columns @ 10cpi ,112 columns @12cpi",
                        },
                        {
                            type: "Paper Handling",
                            value: "Single sheet, Passbook",
                        },
                        {
                            type: "Copies",
                            value: "1+5",
                        },
                        {
                            type: "Special Functions",
                            value: "Automatic platen Gap adjustment, Document Auto alignment, Auto Border recognition ,Automatic Interface set-up",
                        },
                        {
                            type: "Display",
                            value: "LCD with 2×16 characters",
                        },
                        {
                            type: "Graphic Resolution",
                            value: "360×360 dpi",
                        },
                        {
                            type: "Character Set",
                            value: "IBM PC2 CP437",
                        },
                        {
                            type: "Print Direction",
                            value: "Bi-directional",
                        },
                        {
                            type: "Resident Font",
                            value: "OCR A/B, ROMAN , Sans serif , Italic , Courier",
                        },
                        {
                            type: "Resident Emulations",
                            value: "Epson ESC/P2 , IBM 4722/9068 & PP XL24, Olivetti PR40/PR50/PR2 , Unisys EFP series, OKI",
                        },
                        {
                            type: "Standard Interfaces",
                            value: "Serial and USB",
                        },
                        {
                            type: "Input Buffer",
                            value: "128 KB",
                        },
                        {
                            type: "Drivers",
                            value: "Windows 10 (32/64) Windows 8 (32/64) Windows 7 (32/64)",
                        },
                        {
                            type: "Reliability – MTBF",
                            value: "10,000 hours",
                        },
                        {
                            type: "Print Head Life",
                            value: "400 million dots (practically infinite)",
                        },
                        {
                            type: "Ribbon",
                            value: "10 Million Standard",
                        },
                        {
                            type: "Noise Level",
                            value: "< 54db",
                        },
                        {
                            type: "Power Supply",
                            value: "150V — 270V AC",
                        },
                        {
                            type: "Dimensions",
                            value: "394*329*195mm (W*D*H)",
                        },
                        {
                            type: "Weight",
                            value: "8 Kg",
                        },
                        {
                            type: "Scanner (Optional)",
                            value: "YES",
                        },
                        {
                            type: "MSRW (Optional)",
                            value: "YES",
                        },
                        {
                            type: "MICR (Optional)",
                            value: "YES",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/downloads/brochure.pdf`,
                        },
                        {
                            name: "driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/printers/SPEED-40-STAR/downloads/apps/Speed_40_STAR_Windows_Driver.zip`,
                        },
                    ],
                },
            ],
        },
        {
            id: "label-printers",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers.png`,
            info: [
                {
                    id: "lp-46-neo",
                    title: "LP 46 NEO",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO.png`,
                    description: "With Compact design and advanced Technology, LP 46 Neo barcode printer is an outstanding option for Retail, Logistics, Hospitality, and Factories.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Allows printing wider tags",
                                "Thermal Print Head with better wear resistance",
                                "Easy operation and maintenance",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "6 IPS PRINT SPEED", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/1.png`,
                        },
                        {
                          name: "HIGH RIBBON CAPACITY OF 300 MTRS.",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/2.png`,
                        },
                        {
                          name: "COMPACT & USER FRIENDLY",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/3.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Printing Method",
                            value: "Thermal Line Printing – Direct\nThermal & Thermal Transfer",
                        },
                        {
                            type: "Resolution",
                            value: "203 DPI",
                        },
                        {
                            type: "Print Speed",
                            value: "Max. 150 mm/s",
                        },
                        {
                            type: "Print width",
                            value: "Max 108 mm",
                        },
                        {
                            type: "CPU",
                            value: "32 bit RISC CPU",
                        },
                        {
                            type: "Interface",
                            value: "USB + serial",
                        },
                        {
                            type: "Paper Sensor",
                            value: "Transmission sensor removable, Reflection sensor removable",
                        },
                        {
                            type: "1D Barcode",
                            value: "Code 3 of 9, UPCA, UPCE, Interleaved 2 of 5, Code 128, EAN 13, EAN 8, HBIC39 code with check out, codabar industry CODE 25, storage and transportation code, UPC2, UPC5, Code 93, UCC/EAN, Matrix 25, Postnet code, etc",
                        },
                        {
                            type: "2D Barcode",
                            value: "PDF417, MAXICODE, OR code, etc",
                        },
                        {
                            type: "Paper Type",
                            value: "Marked paper, Black Marked paper, Folding paper, notched paper",
                        },
                        {
                            type: "Paper Width",
                            value: "19~120 mm",
                        },
                        {
                            type: "Paper Thickness",
                            value: "0.060.20 mm",
                        },
                        {
                            type: "Paper Roll OD",
                            value: "Max 27 mm",
                        },
                        {
                            type: "Paper Roll ID",
                            value: "25/38 mm",
                        },
                        {
                            type: "Ribbon Width",
                            value: "Max 100 mm",
                        },
                        {
                            type: "Ribbon Length",
                            value: "Max  300 mm",
                        },
                        {
                            type: "Ribbon ID",
                            value: "25.4 mm",
                        },
                        {
                            type: "Button, Light",
                            value: "1 button, 2 lights",
                        },
                        {
                            type: "Input Power",
                            value: "110240V AC. 50/60 Hz",
                        },
                        {
                            type: "Output Power",
                            value: "24  DC, 2.5 A",
                        },
                        {
                            type: "Operation condition",
                            value: "5°C – 45°C, 20% – 90% RH",
                        },
                        {
                            type: "Storage Conditon",
                            value: "40°C – 60°C, 20% – 93% RH",
                        },
                        {
                            type: "Dimensions",
                            value: "210W * 280D * 185H mm",
                        },
                        {
                            type: "Weight",
                            value: "About 2.1 Kg",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/brochure.pdf`,
                        },
                        {
                            name: "installation guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/installation_guide.pdf`,
                        },
                        {
                            name: "BT101 SR4 2961 UL",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/apps/BT101_SR4_2961_UL.zip`,
                        },
                        {
                            name: "Label Printer Config Tool (BPLZ&E) V1.02",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/apps/LabelPrinterConfigTool(BPLZ&E)_V1.02.zip`,
                        },
                        {
                            name: "Linux Driver V1.02",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/apps/LinuxDrv_EN_V1.02.zip`,
                        },
                        {
                            name: "Printer Manager Setup V1.011",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/apps/PrinterManager_setup_V1.011.zip`,
                        },
                        {
                            name: "Windows Driver 2018.3 M 0",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-NEO/downloads/apps/Windows_driver_2018.3_M-0.zip`,
                        },
                    ],
                },
                {
                    id: "lp-46-lite",
                    title: "LP 46 LITE",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE.png`,
                    description: "Durable desktop Label printer designed for best in class performance  producing High-quality labels and are apt for Manufacturing and warehousing, Shipping industry, Small offices, and Retail markets.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Supports both 0.5\" and 1\" ribbon core",
                                "Durable and user-friendly",
                                "Removable black mark reflective sensor",
                                "6 IPS print speed",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "DURABLE AND STRONG DESIGN", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/1.png`,
                        },
                        {
                          name: "HIGH PRINT RATE AT 6-INCHES PER SEC",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/2.png`,
                        },
                        {
                          name: "HIGH RIBBON CAPACITY OF 300 METRES",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/3.png`,
                        },
                        {
                          name: "USB INTERFACE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/4.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Resolution",
                            value: "8 dots/mm 203 DPI",
                        },
                        {
                            type: "Printing Method",
                            value: "Thermal Line Printing – Direct\nThermal & Thermal Transfer",
                        },
                        {
                            type: "Print Speed",
                            value: "Max. 152.4 mm (6\")/second",
                        },
                        {
                            type: "Print width",
                            value: "Max. 108 mm (4.25\")",
                        },
                        {
                            type: "Print Length",
                            value: "Max. 2,794 mm (110\")",
                        },
                        {
                            type: "Physical Dimension",
                            value: "204 mm W x 164 mm H x 280 mm D",
                        },
                        {
                            type: "Weight",
                            value: "2.4 kg (5.29 lbs)",
                        },
                        {
                            type: "Lable roll capacity",
                            value: "127 mm (5\") OD",
                        },
                        {
                            type: "Ribbon",
                            value: "300 m long, max. OD 67 mm, 1\" core ink coated outside",
                        },
                        {
                            type: "Ribbon Width",
                            value: "40 mm to 110 mm (1.6\" ~ 4.3\")",
                        },
                        {
                            type: "Processor",
                            value: "32bit RISC CPU",
                        },
                        {
                            type: "Memory",
                            value: "8 MB Flash memory, 16 MB SDRAM",
                        },
                        {
                            type: "Interface",
                            value: "USB 2.0",
                        },
                        {
                            type: "Power",
                            value: "Input: AC 100-240V, 2.5A, 5060Hz, Output: DC 24V, 2.5A, 60W",
                        },
                        {
                            type: "Sensors",
                            value: "Transmissive gap sensor, Black mark reflective sensor position adjustable",
                        },
                        {
                            type: "Barcode",
                            value: "1D bar code:\nCode 128UCC, Code 128 subsets A, B, C, EAN128, Interleaved 2 of 5, Interleaved 2 of 5 with check digit,Code 39, Code 39 with check digit, Code 93, EAN13, EAN8, UPCA, UPCE, EAN and UPC 2 5 digits addon,Codabar, Postnet, MSI, MSI with check digit, PLESSEY, China post, ITF14, Code 11, TELEPEN, TELEPENN,PLANET, Code49, Deutsche Post Identcode, Deutsche Post Leitcode, LOGMARS\n\n2D bar code:\nGS1 DataBar, GS1 DataMatrix, Maxicode, AZTEC, PDF417, QR Code, Micro PDF 417",
                        },
                        {
                            type: "Printer Language",
                            value: "TSPLEZTM Compatible to EPL, ZPL, ZPL II",
                        },
                        {
                            type: "Media type",
                            value: "Continuous, diecut, black mark, fanfold, notched outside wound",
                        },
                        {
                            type: "Media Width",
                            value: "20 ~ 112 mm (0.8\" ~ 4.4\")",
                        },
                        {
                            type: "Media Thickness",
                            value: "0.06 ~ 0.19 mm (2.36 ~ 7.48 mil)",
                        },
                        {
                            type: "Media Core Diameter",
                            value: "25.4 ~ 38 mm (1\" ~ 1.5\")",
                        },
                        {
                            type: "Label length",
                            value: "5 ~ 2,794 mm (0.2\" ~ 110\")",
                        },
                        {
                            type: "Environment Condition",
                            value: "Operation: 5°C ~ 40°C, 25 ~ 85% noncondensing,\nStorage: -40°C ~ 60°C, 10 ~ 90% noncondensing",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/brochure.pdf`,
                        },
                        {
                            name: "installation guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/installation_guide.pdf`,
                        },
                        {
                            name: "Bartender",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/apps/Bartender.zip`,
                        },
                        {
                            name: "Linux-Driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/apps/Linux-Driver.zip`,
                        },
                        {
                            name: "Utilities",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/apps/Utilities.zip`,
                        },
                        {
                            name: "Windows DLL",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/apps/Windows-DLL.zip`,
                        },
                        {
                            name: "Windows Driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/LP-46-LITE/downloads/apps/Windows-Driver.zip`,
                        },
                    ],
                },
                {
                    id: "zenpert-4T520",
                    title: "ZENPERT 4T520",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520.png`,
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Smooth printing experience",
                                "Large supplies capacity ‒ 300 meters (984') ribbon supply or 110 meters (361') ribbon supply on a 12.7 mm (0.5\") core",
                                "High-class thermal printing head ‒ clear print quality and enhance durability",
                                "Optimized heat radiation design ‒ durable for large volume printing",
                            ],
                        }
                    ],
                    features: [
                        {
                          name: "PRINT WIDTH UPTO 108 MM SUPPORTS MULTI-MEDIA TYPE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/3.png`,
                        },
                        {
                          name: "USB 2.0 FAST TRANSMISSION SPEED",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/4.png`,
                        },
                        {
                          name: "POSITION ADJUSTABLE SENSOR",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/2.png`,
                        },
                        {
                          name: "LARGE SUPPLY CAPACITY 300 MTS (1\") CORE OR 100 MTS (0.5\") CORE RIBBON SUPPLY", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/1.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Print Method",
                            value: "Thermal Transfer & Direct Thermal",
                        },
                        {
                            type: "Resolution",
                            value: "8 dots/mm (203 DPI)",
                        },
                        {
                            type: "Memory",
                            value: "•\t16 MB Flash memory\n•\t64 MB SDRAM",
                        },
                        {
                            type: "Maximum Print Width",
                            value: "108 mm (4.25\")",
                        },
                        {
                            type: "Maximum Print Speed",
                            value: "152 mm , 6 ips",
                        },
                        {
                            type: "Print Length",
                            value: "25,400 mm (1,000\")",
                        },
                        {
                            type: "Interfaces",
                            value: "USB 2.0",
                        },
                        {
                            type: "Media Sensors",
                            value: "•\tTransmissive gap sensor\n•\tBlack mark reflective sensor\n•\tRibbon end sensor\n•\tHead open sensor",
                        },
                        {
                            type: "Maximum Label Length",
                            value: "5 ~ 25,400 mm (0.2\" ~ 1000\")",
                        },
                        {
                            type: "Media Width",
                            value: "19 ~ 118 mm (0.8\" ~ 4.6\")",
                        },
                        {
                            type: "Media Roll Size",
                            value: "127 mm (5\") OD",
                        },
                        {
                            type: "Media Thickness",
                            value: "0.06 ~ 0.19 mm (2.36 ~ 7.48 mil)",
                        },
                        {
                            type: "Media Types",
                            value: "Continuous, die-cut, black mark,  fan-fold (outside wound), notched",
                        },
                        {
                            type: "Ribbon Outside Diameter",
                            value: "300 m long, max. OD 67 mm, 1\" core (ink coated outside)\n110 m long, max. OD 40 mm, 0.5\" core (ink coated outside)",
                        },
                        {
                            type: "Ribbon Width",
                            value: "40 mm ~ 110 mm (1.6\" ~ 4.3\")",
                        },
                        {
                            type: "Operating Temp",
                            value: "0° ~ 40°C",
                        },
                        {
                            type: "Storage Temp",
                            value: "-20° ~ 60°C",
                        },
                        {
                            type: "Operating Humidity",
                            value: "10 ~ 85%",
                        },
                        {
                            type: "Storage Humidity",
                            value: "5 ~ 90%",
                        },
                        {
                            type: "Media Handling",
                            value: "•\tQuick start guide\n•\tUSB cable\n•\tPower cord\n•\tExternal universal switching power supply\n•\tLabel spindle x 1, fixing tab x 2\n•\t1\" ribbon spindle x 2\n•\t0.5\" ribbon spindle x 2",
                        },
                        {
                            type: "1D Barcodes",
                            value: "Code 128UCC, Code 128 subsets A, B, C, EAN128, Interleaved 2 of 5, Interleaved 2 of 5 with check digit, Code 39, Code 39 with check digit, Code 93, EAN13, EAN8, UPCA, UPCE, EAN and UPC 2 (5) digits add-on, Codabar, Postnet, MSI, MSI with check digit, PLESSEY, China post, ITF14, Code 11, TELEPEN, TELEPENN, PLANET, Code49, Deutsche Post Identcode, Deutsche Post Leitcode, LOGMARS",
                        },
                        {
                            type: "2D Barcodes",
                            value: "GS1 DataBar, GS1 DataMatrix, Maxicode, AZTEC, PDF417, QR Code, Micro PDF 417",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/brochure.pdf`,
                        },
                        {
                            name: "User Manual",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/user_manual.pdf`,
                        },
                        {
                            name: "DiagTool V163",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/apps/DiagTool_V163.zip`,
                        },
                        {
                            name: "labelshop TSC 5.32.1658 20170911",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/apps/labelshop_TSC_5.32.1658_20170911.zip`,
                        },
                        {
                            name: "labelshop zenpert",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/apps/labelshop_zenpert.zip`,
                        },
                        {
                            name: "Zenpert 2020.1 M 4",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/apps/Zenpert_2020.1_M-4.zip`,
                        },
                        {
                            name: "windows driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/label_printers/ZENPERT-4T520/downloads/apps/Zenpert_windows driver.zip`,
                        },
                    ],
                },
            ],
        },
        {
            id: "keyboards-and-mice",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice.png`,
            info: [
                {
                    id: "gold",
                    title: "GOLD",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold.png`,
                    description: "Extremely user-friendly and compatible, Smooth handling for professionals and those who are adept at Touch Typing (typing without seeing the keyboard), Most useful for banking and insurance professionals",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Guaranteed 50 Million plus strokes per key",
                                "Fitted with mechanical switches for long life",
                                "Laser Etched Characters on Keycaps with an everlasting presence",
                                "The keyboards are all enabled with the Rupee symbols",
                                "The option of working in two languages as all keyboards are bilingual",
                                "Highly reliable, with more than 200,000 Hrs MTBF",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "ARRAY OF 104 KEYS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold/1.png`,
                        },
                        {
                          name: "MECHANICAL KEYS  FOR LONG LIFE",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold/2.png`,
                        },
                        {
                          name: "CHOICE OF INTERFACE – USB / PS2",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold/4.png`,
                        },
                        {
                          name: "9 VERNACULAR LANGUAGES TO CHOOSE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold/3.png`,
                        },
                        {
                          name: "SCULPTED KEYCAP WITH THE CLICK OF TOUCH FEELING",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/gold/5.png`,
                        },
                    ],
                },
                {
                    id: "platina",
                    title: "Platina",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina.png`,
                    description: "Designed to withstand extreme conditions, the Platina is the toughest mechanical keyboard one can buy in the market. In addition to being user-friendly and compatible, the product is resistance to dust.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Durable keys that can last up to 50 million keystrokes",
                                "Laser Etched/Bright & Bold Key Characters",
                                "Strong Tilt Legs",
                                "Compatible with all batteries",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "DUSTPROOF KEY SWITCHES", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/1.png`,
                        },
                        {
                          name: "DURABLE KEYS THAT CAN LAST UP TO 50 MILLION KEYSTROKES",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/4.png`,
                        },
                        {
                          name: "LASER ETCHED",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/6.png`,
                        },
                        {
                          name: "BRIGHT & BOLD KEY CHARACTERS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/8.png`,
                        },
                        {
                          name: "STRONG TILT LEGS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/3.png`,
                        },
                        {
                            name: "Build-in slot for dongle",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/5.png`,
                        },
                        {
                            name: "2 * AAA Battery",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/7.png`,
                        },
                        {
                            name: "SUPPORTS ALL OPERATING SYSTEMS WIN7 ,WIN8, WIN10 & LINUX & MAC OS & CHROME OS",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/2.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Model",
                            value: "Platina",
                        },
                        {
                            type: "Wireless Receiver",
                            value: "Nano",
                        },
                        {
                            type: "Wireless operating distance",
                            value: "10m",
                        },
                        {
                            type: "Wirelss Technology",
                            value: "2.4G RF",
                        },
                        {
                            type: "OS Required",
                            value: "Windows® 7, Windows 8, Windows 10 or later\nMac OS® X 10.8 or later\nChrome OS\nLinux® kernel 2.6+",
                        },
                        {
                            type: "Battery",
                            value: "2 *AAA Battery",
                        },
                        {
                            type: "Weight",
                            value: "99 g",
                        },
                        {
                            type: "Material",
                            value: "ABS",
                        },
                        {
                            type: "Color",
                            value: "Black / White",
                        },
                        {
                            type: "Dimension",
                            value: "120 * 74 * 40 mm",
                        },
                        {
                            type: "Standby Mode Current",
                            value: "0.24mA",
                        },
                        {
                            type: "Active Mode Current",
                            value: "1.7mA",
                        },
                        {
                            type: "Sleep Mode Current",
                            value: "0.00 mA",
                        },
                        {
                            type: "Sleep Mode Time",
                            value: "5 mins",
                        },
                        {
                            type: "USB Dongle Required",
                            value: "Yes",
                        },
                        {
                            type: "Battery Life",
                            value: "12 Months",
                        },
                        {
                            type: "On/Off Switch",
                            value: "Available",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/platina/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "champ-hd",
                    title: "CHAMP-HD",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd.png`,
                    description: "Extremely user-friendly and compatible, Smooth handling, lightweight and slim, Ergonomic design, thus enabling easy and effortless keystrokes. The dedicated multimedia switches helps to increase productivity.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Wired Membrane Keyboard",
                                "10 Million Keystrokes",
                                "Enabled with Multimedia function",
                                "Elegant Palm rest",
                                "Strong tilt legs",
                                "Laser etched keys",
                                "Indicator LED : Num Lock, Caps lock, Scroll lock",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "ARRAY OF 104 KEYS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd/1.png`,
                        },
                        {
                          name: "MEMBRANE KEYS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd/2.png`,
                        },
                        {
                          name: "MULTIMEDIA FUNCTIONS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd/3.png`,
                        },
                        {
                          name: "PLUG AND PLAY INTERFACE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd/4.png`,
                        },
                        {
                          name: "SCULPTED KEYCAP WITH THE CLICK OF TOUCH FEELING",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd/5.png`,
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/champ-hd/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "champ-elite-wireless-combo",
                    title: "CHAMP ELITE WIRELESS COMBO",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO.png`,
                    description: "Extremely user-friendly and compatible, Smooth handling, lightweight and slim, Ergonomic design, thus enabling easy and effortless keystrokes. The function enabled Multi-media keys helps to increase productivity.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "KEYBOARD\n◦\t2.4Ghz Wireless Technology\n◦\tKey Life – 10 Million Key Strokes\n◦\tLong lasting UV coated abrasion resistant keys\n◦\tUnique slim design with an elegant palm rest for working comfort\n◦\tEquipped with function enabled Multi media options\n◦\tLED indicators for Num lock, CAPS lock and battery low\n◦\tEnhanced Battery Management system , compatible with all batteries\n◦\t2 * AAA batteries\n",
                                "MOUSE\n◦\tAdvanced optical tracking with 1600 dpi\n◦\tAuto-Power saving sleep mode\n◦\tErgonomic design\n◦\tBuilt-in compartment for dongle\n◦\t2 * AAA batteries",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "ARRAY OF 103 KEYS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO/1.png`,
                        },
                        {
                          name: "LED INDICATORS FOR NUM LOCK, CAPS LOCK AND BATTERY LOW",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO/5.png`,
                        },
                        {
                          name: "MULTIMEDIA FUNCTIONS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO/4.png`,
                        },
                        {
                          name: "UNIQUE SLIM DESIGN WITH AN ELEGANT PALM REST FOR WORKING COMFORT", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO/2.png`,
                        },
                        {
                          name: "LONG LASTING UV COATED ABRASION RESISTANT KEYS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO/3.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Keyboard",
                            value: "",
                        },
                        {
                            type: "No. Keys",
                            value: "103",
                        },
                        {
                            type: "Multimedia keys",
                            value: "Fn Enabled Mulitimedia",
                        },
                        {
                            type: "Character etching type",
                            value: "Laser Etching",
                        },
                        {
                            type: "Keyboard Material",
                            value: "ABS",
                        },
                        {
                            type: "Keycaps Material",
                            value: "ABS",
                        },
                        {
                            type: "Dimension",
                            value: "450.0 x 2.0 x 175.5 mm (KB)",
                        },
                        {
                            type: "Weight",
                            value: "925 g",
                        },
                        {
                            type: "Switch Life",
                            value: "10 million Durable Use-life",
                        },
                        {
                            type: "Wireless Technology",
                            value: "2.4G RF",
                        },
                        {
                            type: "Keyboard Interface",
                            value: "Wireless",
                        },
                        {
                            type: "Wireless operating distance",
                            value: "10m",
                        },
                        {
                            type: "Battery Specifications",
                            value: "AAA Battery 2 nos",
                        },
                        {
                            type: "Certification",
                            value: "CE/FCC",
                        },
                        {
                            type: "",
                            value: "",
                        },
                        {
                            type: "MOUSE",
                            value: "",
                        },
                        {
                            type: "Movement Detection Technology",
                            value: "Optical",
                        },
                        {
                            type: "Wireless Receiver",
                            value: "Nano",
                        },
                        {
                            type: "Wireless operating distance",
                            value: "10m",
                        },
                        {
                            type: "Wireless Technology",
                            value: "2.4G RF",
                        },
                        {
                            type: "Movement Resolution DPI",
                            value: "1600 dpi",
                        },
                        {
                            type: "Fwd/ Rev Features",
                            value: "Available",
                        },
                        {
                            type: "Battery",
                            value: "AAA Battery 2 nos",
                        },
                        {
                            type: "Sleep Mode Time",
                            value: "8 Mins",
                        },
                        {
                            type: "USB Dongle Required",
                            value: "Yes",
                        },
                        {
                            type: "On/Off Switch",
                            value: "Available",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-ELITE-WIRELESS-COMBO/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "champ-m120",
                    title: "CHAMP M120",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120.png`,
                    description: "Extremely user-friendly and compatible. Perfect fit for professionals who work in demanding business environments.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "1.5 Million clicks ",
                                "Three function buttons",
                                "Optical sensor",
                                "Resolution 1200 DPI",
                                "USB plug & play",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "1.5 MILLION CLICKS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120/2.png`,
                        },
                        {
                          name: "3 FUNCTION BUTTONS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120/1.png`,
                        },
                        {
                          name: "USB PLUG & PLAY",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120/5.png`,
                        },
                        {
                          name: "1200 DPI RESOLUTION", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120/3.png`,
                        },
                        {
                          name: "OPTICAL SENSOR",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120/4.png`,
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/CHAMP-M120/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "wm-416",
                    title: "WM-416",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse.png`,
                    description: "The WM 416 is a compact, less noisy, more comfortable wireless mouse with a built-in dongle slot. The power switch allows you to turn the mouse off when not in use, saving battery life while using it longer by not draining the batteries. It comfortably fits in your hand and rests on your palm with its ergonomic design. It is best suited for professionals working in demanding business environments. ",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Advanced optical tracking with 1600 DPI",
                                "Auto power off & on/off switch",
                                "Ergonomic Grip",
                                "Built-in slot for dongle",
                                "2 AAA batteries",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "Advanced optical tracking with 1600 DPI", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse/1.png`,
                        },
                        {
                          name: "Auto power off & on/off switch",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse/2.png`,
                        },
                        {
                          name: "Ergonomic Grip",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse/3.png`,
                        },
                        {
                          name: "Built-in slot for dongle", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse/4.png`,
                        },
                        {
                          name: "2 AAA batteries",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/keyboards_and_mice/WM-416-Wireless-Mouse/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Movement Resolution",
                            value: "DPI 1200/1600 dpi",
                        },
                        {
                            type: "No Of Buttons",
                            value: "4",
                        },
                        {
                            type: "On/Off Switch",
                            value: "Available",
                        },
                        {
                            type: "Auto Power off",
                            value: "Available",
                        },
                        {
                            type: "Battery",
                            value: "AAA Battery 2nos",
                        },
                        {
                            type: "OS Support (at release)",
                            value: "Windows / Linux / MAC OS",
                        },
                        {
                            type: "Warranty",
                            value: "1 Year",
                        },
                    ],
                }
            ],
        },
        {
            id: "currency-counter",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter.png`,
            info: [
                {
                    id: "cc-232-classic",
                    title: "CC 232 CLASSIC",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic.png`,
                    description: "CC-232 Classic is designed with big front display and buttons with display range of 1-999. It automatically detects fake notes with ultraviolet and magnetic radiations and also counts old and new notes with high level of precision and speed (Super Fast Counting Speed -1200 Notes / Minute). It supports batching, adding and Self-examination functions",
                    features: [
                        {
                          name: "Suitable for Heavy duty operations", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/1.png`,
                        },
                        {
                          name: "Super fast currency counting: 1200 notes/min",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/2.png`,
                        },
                        {
                          name: "Supports batch function with in-built display",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/3.png`,
                        },
                        {
                          name: "Detects and counts both old and new currencies", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/3.png`,
                        },
                        {
                          name: "Has automatic count mode",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/4.png`,
                        },
                        {
                            name: "Easy maintenance : Self check function",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Detection Tech",
                            value: "UV , MG, IR",
                        },
                        {
                            type: "Mixed Notes",
                            value: "NO",
                        },
                        {
                            type: "Denomination Sorting",
                            value: "NO",
                        },
                        {
                            type: "No. Of Stackers",
                            value: "1",
                        },
                        {
                            type: "Hopper Capacity",
                            value: "200",
                        },
                        {
                            type: "Stacker Capacity",
                            value: "200",
                        },
                        {
                            type: "Counting Speed",
                            value: "1200PCS/MIN",
                        },
                        {
                            type: "Batch Function",
                            value: "YES",
                        },
                        {
                            type: "Counterfeit Alarm",
                            value: "YES",
                        },
                        {
                            type: "Heavy Duty Motor",
                            value: "YES",
                        },
                        {
                            type: "Display Range",
                            value: "1-999",
                        },
                        {
                            type: "Automatic Currency Feeding",
                            value: "YES",
                        },
                        {
                            type: "Inbuilt Display",
                            value: "SINGLE",
                        },
                        {
                            type: "External Dispaly",
                            value: "NO",
                        },
                        {
                            type: "Interfaces",
                            value: "LAN",
                        },
                        {
                            type: "Software Upgradation",
                            value: "YES",
                        },
                        {
                            type: "I/P Voltage",
                            value: "110~220V  60/50HZ",
                        },
                        {
                            type: "Power Consumtion",
                            value: "≤90w",
                        },
                        {
                            type: "Auto/Self Examination",
                            value: "YES",
                        },
                        {
                            type: "** Fake Note Detection will have its limitations and vary from time to time",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-Classic/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "cc-453-star+",
                    title: "CC 453 STAR+",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP.png`,
                    description: "CC 453 Star+ is designed with big front display and buttons with display range of 1-999. It automatically detects fake notes with ultraviolet and magnetic radiations and also counts old and new notes with high level of precision and speed (Super Fast Counting Speed -1000 Notes / Minute). It supports batching, adding and Self-examination functions",
                    features: [
                        {
                          name: "Suitable for Heavy duty operations", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/1.png`,
                        },
                        {
                          name: "Super fast currency counting: 1000 notes/min",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/2.png`,
                        },
                        {
                          name: "Supports batch function with in-built display",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/3.png`,
                        },
                        {
                          name: "Detects the half-note, chain-note and double-note", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/3.png`,
                        },
                        {
                          name: "Has automatic start, stop and clear",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/4.png`,
                        },
                        {
                            name: "Software can be updated through RS-232 port",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Detection Tech",
                            value: "UV, MG, IR, 3D, COLOR",
                        },
                        {
                            type: "Hopper capacity",
                            value: "300 ( New Notes)",
                        },
                        {
                            type: "Stacker capacity",
                            value: "200-250 (New Notes)",
                        },
                        {
                            type: "Counting speed",
                            value: "1000 notes/min",
                        },
                        {
                            type: "Counting Display",
                            value: "4 digits",
                        },
                        {
                            type: "Batch Display",
                            value: "3 digits",
                        },
                        {
                            type: "Batch function",
                            value: "Yes",
                        },
                        {
                            type: "Mixed notes",
                            value: "Yes",
                        },
                        {
                            type: "Denomination sorting",
                            value: "Yes",
                        },
                        {
                            type: "Counterfeit alarm",
                            value: "Yes",
                        },
                        {
                            type: "Automatic currency feeding",
                            value: "Yes",
                        },
                        {
                            type: "Size detection",
                            value: "Yes",
                        },
                        {
                            type: "Half note detection",
                            value: "Yes",
                        },
                        {
                            type: "Double note detection",
                            value: "Yes",
                        },
                        {
                            type: "Total value function",
                            value: "Yes",
                        },
                        {
                            type: "Inbuilt display",
                            value: "Yes",
                        },
                        {
                            type: "External display",
                            value: "Yes",
                        },
                        {
                            type: "Software upgradation",
                            value: "Yes",
                        },
                        {
                            type: "Auto/Self Examination",
                            value: "Yes",
                        },
                        {
                            type: "Interfaces",
                            value: "RS232",
                        },
                        {
                            type: "Dimension",
                            value: "292x248x175 mm",
                        },
                        {
                            type: "Size of Countable banknotes",
                            value: "MIN: 50x110mm MAX: 90x190mm",
                        },
                        {
                            type: "Thickness of Countable banknotes",
                            value: "0.075mm – 0.15mm",
                        },
                        {
                            type: "Power Supply",
                            value: "AC 220V, 50Hz",
                        },
                        {
                            type: "Power Consumption",
                            value: "≤80W",
                        },
                        {
                            type: "Ambient Temperature",
                            value: "0°C – 40°C",
                        },
                        {
                            type: "Ambient Humidity",
                            value: "25% – 80%",
                        },
                        {
                            type: "** Fake Note Detection will have its limitations and vary from time to time",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-453-StarP/downloads/brochure.pdf`,
                        },
                    ],
                },
                {
                    id: "cc-232-classic+",
                    title: "CC 232 CLASSIC+",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP.png`,
                    description: "CC 232 Classic+ is an automatic, reliable, professional currency-counting machine with advanced technology and precise sensors for fast cash counting. Its UV and magnetic radiation detect counterfeit notes with LCD red alerts and can also count old and new currencies. In addition to batching, adding, and self-examining functions, it has automated start, stop, and clear.",
                    features: [
                        {
                          name: "Suitable for Heavy Duty Operations", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/4.png`,
                        },
                        {
                          name: "Detects and Counts Both Old and New Currencies",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/2.png`,
                        },
                        {
                          name: "Automatic start, stop and clear",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/1.png`,
                        },
                        {
                          name: "Supports Batch Function With In-built Display", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/6.png`,
                        },
                        {
                          name: "Super Fast Currency Counting: 1000 Notes/min",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/5.png`,
                        },
                        {
                            name: "Easy Maintenance: Self Check Function",
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/3.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Hopper Capacity",
                            value: "200 bills",
                        },
                        {
                            type: "Stacker Capacity",
                            value: "200 bills",
                        },
                        {
                            type: "Counting Speed",
                            value: "1000 pcs/min",
                        },
                        {
                            type: "Dimension",
                            value: "280x255x190 mm",
                        },
                        {
                            type: "Size of Countable Notes",
                            value: "50×110-90×170 mm",
                        },
                        {
                            type: "Thickness of Countable Notes",
                            value: "0.075-0.15 mm",
                        },
                        {
                            type: "Counting Display",
                            value: "4 digits",
                        },
                        {
                            type: "Batch Display",
                            value: "3 digits",
                        },
                        {
                            type: "Net Weight",
                            value: "4.5 kg",
                        },
                        {
                            type: "Gross Weight",
                            value: "5.5 kg",
                        },
                        {
                            type: "Power Supply",
                            value: "AC220V±10% 50Hz",
                        },
                        {
                            type: "Power Consumption",
                            value: "580W",
                        },
                        {
                            type: "Ambient Temperature",
                            value: "0°C – 40°C",
                        },
                        {
                            type: "Ambient Humidity",
                            value: "25% – 80%",
                        },
                        {
                            type: "Feeding System",
                            value: "Roller Friction System",
                        },
                        {
                            type: "** Fake Note Detection will have its limitations and vary from time to time",
                            value: "",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/currency_counter/CC-232-ClassicP/downloads/brochure.pdf`,
                        },
                    ],
                }
            ],
        },
        {
            id: "e-cash-registers",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers.png`,
            info: [
                {
                    id: "pt-262",
                    title: "PT 262",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262.png`,
                    description: "Designed to work in most challenging terrains, TVS PT-262 is the preferred billing terminal and empowers Indian retailers who prefer an electronic register over a personal computer.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Easy to install, no more installation hassles",
                                "The built-in thermal printer offers extreme speed",
                                "Compact and light – weighs only 2 kgs",
                                "Zero Maintenance – Low Servicing Costs",
                                "Non-Stop billing, built for an effective and efficient experience",
                                "Track your sales by printing reports – Commission Reports, Bill-wise summary, Monthly reports, and many more."
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "BUILT-IN THERMAL PRINTER OFFERS EXTREME SPEED", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/1.png`,
                        },
                        {
                          name: "COMPACT AND LIGHT WEIGHT- WEIGHS ONLY 2KG",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/2.png`,
                        },
                        {
                          name: "EASY TO INSTALL – NO MORE INSTALLATION HASSLES",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/3.png`,
                        },
                        {
                          name: "TRACK YOUR SALES BY PRINTING REPORTS-BILL WISE, MONTH WISE AND MUCH MORE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/4.png`,
                        },
                        {
                          name: "ZERO MAINTENANCE – LOW SERVICING COSTS",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Model Name",
                            value: "PT-262",
                        },
                        {
                            type: "Printer",
                            value: "1 * 57 mm thermal printer, Max. 50 mm/s printer speed",
                        },
                        {
                            type: "PLU / Dept",
                            value: "2000 PLUs, 40 departments, 6 hotkeys",
                        },
                        {
                            type: "Cashier / Clerks",
                            value: "4 cashiers / clerks, 30 salesman",
                        },
                        {
                            type: "Keyboard",
                            value: "Raised membrane keyboard, 25 function keys, 6 hotkeys, Termination keys",
                        },
                        {
                            type: "Display",
                            value: "6 + 8 alphanumeric LCD operator – display, 6 + 8 alphanumeric LCD customer – display",
                        },
                        {
                            type: "Connective Ports",
                            value: "Rs.232 for Barcode / PC / scale, PS/2 for Barcode, Drawer",
                        },
                        {
                            type: "Retail Functions",
                            value: "Stock management, Duplicate bill, Price & VIP price, Salesman name on bill, Clerk name on bill, RA & PO function, Surcharge, Separate keys for discount, Price, mark up, etc. Font size change, Initialize bill Sl. No, Initialize report Sl. No. Machine No. program",
                        },
                        {
                            type: "Types of Reports",
                            value: "PLU report, Dept report, Clerk report, Term report, Stock Report, Returnreport, Cash-in drawer report, Z-sales report, Commission report, Bill wise summary report, Monthly report, Tax report",
                        },
                        {
                            type: "Optional",
                            value: "Cash drawer, USB cable, USB box, Multiplexer",
                        },
                        {
                            type: "Accessories",
                            value: "1 D9P4 PC cable, 1 CD for PC, 1 SMPS, User Manual",
                        },
                        {
                            type: "Power",
                            value: "7.0 V 2.0A SMPS power supply | 6v 3.2AH (optional) | Rechargeable battery (optional)",
                        },
                        {
                            type: "Dimension",
                            value: "355 * 231 * 120 mm ",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/downloads/brochure.pdf`,
                        },
                        {
                            name: "installation guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/downloads/installation_guide.pdf`,
                        },
                        {
                            name: "driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/downloads/apps/driver.zip`,
                        },
                        {
                            name: "PC Software",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-262/downloads/apps/PC-Software.zip`,
                        },
                    ],
                },
                {
                    id: "pt-235",
                    title: "PT 235",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235.png`,
                    description: "PT 235 is a compact, lightweight, multi-functional handheld Electronic Cash Register with a KOT feature, allowing retailers to operate the machine seamlessly with their existing POS. It has a rapid print speed and an inbuilt battery for prolonged usage with an automatic sleep mode that powers down when idle. It comes with an intuitive bank-level security design for data protection.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Print speed up to 150mm/s",
                                "Super tiny, unique front paper-out design",
                                "Power saving",
                                "Long battery life – prints 1500 receipts continuously",
                                "Wonderful touch button experience",
                                "Secured Transaction",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "Long battery life – prints 1500 receipts continuously", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/1.png`,
                        },
                        {
                          name: "Print speed up to 150mms",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/2.png`,
                        },
                        {
                          name: "Secured Transaction",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/3.png`,
                        },
                        {
                          name: "Super tiny, unique front paper-out design", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/4.png`,
                        },
                        {
                          name: "Wonderful touch button experience",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Parameter",
                            value: "Spec",
                        },
                        {
                            type: "Printer/Speed",
                            value: "Printer: 1*57MM thermal printer, max.150mm/s",
                        },
                        {
                            type: "Power Supply Details",
                            value: "Power supply: DC 14V,2.5A, Rechargeable battery: 7.4V / 3000mAh",
                        },
                        {
                            type: "Type of the keyboard",
                            value: "30 Key silicone keyboard",
                        },
                        {
                            type: "Operator-Display",
                            value: "65*132 LCD 5 lines",
                        },
                        {
                            type: "Customer-Display",
                            value: "6+8 LCD 1 line",
                        },
                        {
                            type: "No of PLU's",
                            value: "5000",
                        },
                        {
                            type: "Ports",
                            value: "1*Drawer+RS232+1* USB +1*Ethernet",
                        },
                        {
                            type: "Pen Drive Slot Support",
                            value: "Yes",
                        },
                        {
                            type: "Supported Functions",
                            value: "Barcode, Logo, Rupee Smbol, GST Inclusion, Petrol/Oilr",
                        },
                        {
                            type: "Weight",
                            value: "675g",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/downloads/brochure.pdf`,
                        },
                        {
                            name: "user manual",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/downloads/user_manual.pdf`,
                        },
                        {
                            name: "software",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/e_cash_registers/PT-235/downloads/software.zip`,
                        },
                    ],
                }
            ],
        },
        {
            id: "mobile-printers",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers.png`,
            info: [
                {
                    id: "mp-280-lite",
                    title: "MP 280 LITE",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE.png`,
                    description: "MP 280 LITE, an ultra-compact 2″ Portable receipt printer connects through Bluetooth and renders mobility to the user. This mobile thermal printer can be deployed for various operations including Ticketing, Billing, Hospitality, and so on.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Bluetooth Connectivity V4.0 + USB",
                                "Bluetooth pairing- Auto Reconnect",
                                "Easy Paper Loading",
                                "Light weight & Easy to operate",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "BLUETOOTH CONNECTVITY V4.0 + USB", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/4.png`,
                        },
                        {
                          name: "BLUETOOTH PAIRING-AUTO RECONNECT",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/3.png`,
                        },
                        {
                          name: "EASY PAPER LOADING",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/2.png`,
                        },
                        {
                          name: "LIGHT WEIGHT & EASY TO OPERATE", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/1.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Printing Method",
                            value: "Thermal Line Printing",
                        },
                        {
                            type: "Print Speed",
                            value: "Upto 70mm/sec",
                        },
                        {
                            type: "Print resolution",
                            value: "203 dpi",
                        },
                        {
                            type: "Print Width",
                            value: "Upto 48mm",
                        },
                        {
                            type: "Sensor Paper end",
                            value: "Reflective sensor",
                        },
                        {
                            type: "Media type",
                            value: "Receipt",
                        },
                        {
                            type: "Media Width",
                            value: "58mm",
                        },
                        {
                            type: "Media roll diameter",
                            value: "Upto 40mm",
                        },
                        {
                            type: "Media thickness",
                            value: "60 to 100μm",
                        },
                        {
                            type: "Supply method",
                            value: "Easy paper loading",
                        },
                        {
                            type: "Dimension",
                            value: "115mm*84mm*38mm",
                        },
                        {
                            type: "Weight",
                            value: "265g",
                        },
                        {
                            type: "Operating temperature",
                            value: "-10⁰C-50⁰C",
                        },
                        {
                            type: "Storage temperature",
                            value: "-20⁰C-60⁰C",
                        },
                        {
                            type: "Character size",
                            value: "12*24 dot font",
                        },
                        {
                            type: "Character per line",
                            value: "24 characters/line\n32 characters/line\n42 characters/line",
                        },
                        {
                            type: "Character sets",
                            value: "Alpha-numeric : 95\nExpanded graphics: 128*33 page",
                        },
                        {
                            type: "Instruction set",
                            value: "ESC/POS compatible",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/downloads/brochure.pdf`,
                        },
                        {
                            name: "installation guide",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/downloads/installation_guide.pdf`,
                        },
                        {
                            name: "Printer Tools (eclipse+ADT)",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/downloads/apps/PrinterTools(eclipse+ADT).zip`,
                        },
                        {
                            name: "Printer Tools (studio)",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/downloads/apps/PrinterTools1(studio).zip`,
                        },
                        {
                            name: "windows driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-280-LITE/downloads/apps/windows-driver.rar`,
                        },
                    ],
                },
                {
                    id: "mlp-360",
                    title: "MLP 360",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360.png`,
                    description: "MLP 360, a compact 3” Portable Receipt/ Label printer connecting through bluetooth and renders mobility for user. This mobile label printer can be deployed for various applications including Field services, Home Sample collection for diagnosis, Hospitality and so.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Portable mobile printer with Bluetooth connectivity",
                                "Built-in OLED display for easy printer management",
                                "Economical solution for printing both receipts and labels in one printer",
                                "With rechargeable battery",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "PORTABLE MOBILE PRINTER WITH BLUETOOTH CONNECTIVITY", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/4.png`,
                        },
                        {
                          name: "BUILT-IN OLED DISPLAY FOR EASY PRINTER MANAGEMENT",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/3.png`,
                        },
                        {
                          name: "ECONOMICAL SOLUTION FOR PRINTING BOTH RECEIPTS AND LABELS IN ONE PRINTER",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/2.png`,
                        },
                        {
                          name: "WITH RECHARGEABLE BATTERY", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/1.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Printing Method",
                            value: "Thermal Line Printing",
                        },
                        {
                            type: "Print Speed",
                            value: "Upto 60 mm/s",
                        },
                        {
                            type: "Print Width",
                            value: "Upto 72mm",
                        },
                        {
                            type: "Interface",
                            value: "USB +Bluetooth 4.2",
                        },
                        {
                            type: "Paper Width",
                            value: "80mm (Receipt); Max. 3 inch (Label)",
                        },
                        {
                            type: "Roll Diameter",
                            value: "Max. 50mm",
                        },
                        {
                            type: "Paper Thickness",
                            value: "0.06-0.08mm",
                        },
                        {
                            type: "Barcode Type",
                            value: "UPC-A/JSN 13(EAN 13)/ JAN8(EAN8)/ CODE39/ITF/CODABAR /CODE 93/CODE 128\nQR code",
                        },
                        {
                            type: "Led Screen",
                            value: "OLed screen , 30.4*14.5mm ; Effective show size 25.58*6.38mm",
                        },
                        {
                            type: "Character",
                            value: "CodePage, GBK , UTF -8, Arabic , Unicode",
                        },
                        {
                            type: "Print Command",
                            value: "Compatible with ESC/POS (Receipt), TSPL (Label), CPCL (Waybill)",
                        },
                        {
                            type: "Character Size",
                            value: "12*24dots",
                        },
                        {
                            type: "Input Buffer",
                            value: "1.5K bytes",
                        },
                        {
                            type: "Power Adapter Input",
                            value: "AC220V; output DC9V/1A",
                        },
                        {
                            type: "Lithium Battery",
                            value: "2600 mAh/7.4V",
                        },
                        {
                            type: "Charging Time",
                            value: "3 hours",
                        },
                        {
                            type: "Stand-by Time",
                            value: "6~7 days when it's full of energy",
                        },
                        {
                            type: "Mechanical Properties",
                            value: "50KM",
                        },
                        {
                            type: "Operating System",
                            value: "Android , iOS , Windows",
                        },
                        {
                            type: "Working Environment temperature",
                            value: "12 * 24 dots0°C ~50°C; humidity 20%~85%",
                        },
                        {
                            type: "Storage Environment temperature",
                            value: "-20°C~60°C, humidity 5%~95%",
                        },
                        {
                            type: "Product Size",
                            value: "12.5×11.5×6cm",
                        },
                        {
                            type: "Product Weight",
                            value: "0.5KG",
                        },
                        {
                            type: "Print Head Life",
                            value: "50 KM",
                        },
                        {
                            type: "Working range",
                            value: "~100 mts",
                        },
                        {
                            type: "Drop specification",
                            value: "1.5m",
                        },
                        {
                            type: "IP rating",
                            value: "IP54",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/downloads/brochure.pdf`,
                        },
                        {
                            name: "Label Software and driver for label Pc-20191220T092603Z-001",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/downloads/apps/Label-Software-and-driver-for-label-pc-20191220T092603Z-001.rar`,
                        },
                        {
                            name: "Mobile App",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/downloads/apps/Mobile-App.rar`,
                        },
                        {
                            name: "SDK 20190319 1800",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/downloads/apps/SDK_20190319-1800.rar`,
                        },
                        {
                            name: "Windows Driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-360/downloads/apps/Windows-Driver.7z`,
                        },
                    ],
                },
                {
                    id: "mp-22",
                    title: "MP 22",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22.png`,
                    description: "MP 22 is a compact, lightweight, and high-performance 2-inch Impact Printer with 2100 mAh high-quality battery for exceptional battery backup. Its portable design, Bluetooth/USB connectivity, and wide compatibility make it a multifunctional printer. It prints effortlessly with Windows and Android operating systems. ",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Compact and Lightweight",
                                "Durable 2100 mAh battery",
                                "Interface: Bluetooth/USB ",
                                "Handy & easily portable",
                                "Printing life: 1.5 million lines",
                                "Supports Windows, Android & iOS",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "Portable & Compact Design", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22/4.png`,
                        },
                        {
                          name: "Paper width – 58mm",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22/3.png`,
                        },
                        {
                          name: "Printing Speed - 5.5mm/sec",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22/2.png`,
                        },
                        {
                          name: "Interface: USB , Bluetooth", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22/1.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Power supply",
                            value: "USB 5V charging",
                        },
                        {
                            type: "Battery",
                            value: "7.4v-2100mah",
                        },
                        {
                            type: "Printing life",
                            value: "1.5million lines",
                        },
                        {
                            type: "Weight",
                            value: "200g (package included 500g)",
                        },
                        {
                            type: "Size",
                            value: "120mm*100mm*50mm",
                        },
                        {
                            type: "Interface",
                            value: "Bluetooth & USB",
                        },
                        {
                            type: "Bluetooth",
                            value: "Bluetooth 4.0",
                        },
                        {
                            type: "Printing method",
                            value: "8 needles unidirectional printing",
                        },
                        {
                            type: "Printing Speed",
                            value: "5.5mm/s",
                        },
                        {
                            type: "Print Width",
                            value: "48mm",
                        },
                        {
                            type: "Paper Width",
                            value: "58mm",
                        },
                        {
                            type: "Paper thickness",
                            value: "0.06-0.08mm",
                        },
                        {
                            type: "Sensor",
                            value: "Black mark sensor",
                        },
                        {
                            type: "Installed Character",
                            value: "ASII (8*16 12*24 16*32 24*48 32*64)\nGB13080 (13*13\t24*2468*48)",
                        },
                        {
                            type: "Instruction system",
                            value: "ESCPOS",
                        },
                        {
                            type: "Resolution",
                            value: "5 dot/mm",
                        },
                        {
                            type: "Paper Cut",
                            value: "Manually",
                        },
                        {
                            type: "System Language",
                            value: "English",
                        },
                        {
                            type: "Support System",
                            value: "Android/I0S/Windows/Win CE",
                        },
                        {
                            type: "Paper type",
                            value: "2 page copy paper",
                        },
                        {
                            type: "Paper roll",
                            value: "Max 40mm",
                        },
                        {
                            type: "Storage Temp",
                            value: "-20°C – 70°C",
                        },
                        {
                            type: "Humidity",
                            value: "0-95%",
                        },
                        {
                            type: "Packing List",
                            value: "1*printer, 1*adapter, 1*paper roll, 1 box package",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22/downloads/brochure.pdf`,
                        },
                        {
                            name: "driver",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MP-22/downloads/driver.zip`,
                        },
                    ],
                },
                {
                    id: "mlp-370",
                    title: "MLP 370",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370.png`,
                    description: "The MLP 370 is a compact, lightweight and handheld 3” portable receipt/label printer. Its USB and Bluetooth connectivity allows you to print labels and receipts on the go. Its long-lasting battery life makes it a sturdy device and high-performing device. This printer’s ergonomic design is convenient to hold and operate, suiting various business needs like, field services, healthcare, ticketing, postal department, logistics and warehouses.",
                    bullets: [
                        {
                            heading: "advantages",
                            data: [
                                "Compact and Portable design",
                                "Dual mode bluetooth",
                                "High printing speed",
                                "Compatible with commands",
                                "Portable and handheld",
                            ],
                        },
                    ],
                    features: [
                        {
                          name: "Printing speed of 70 mm/s (2.7\")", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/1.png`,
                        },
                        {
                          name: "Built-in OLED display for easy printer management",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/2.png`,
                        },
                        {
                          name: "Long lasting battery 2500 mAh",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/3.png`,
                        },
                        {
                          name: "Supports advanced emulations such as TSPL, EPL, ZPL, DPL, CPCL, ESC/POS commands", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/6.png`,
                        },
                        {
                          name: "Sturdy and Lightweight",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/4.png`,
                        },
                        {
                            name: "Storage upto 8MB SDRAM", 
                            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/5.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Printing Type",
                            value: "3 Inch",
                        },
                        {
                            type: "Print method",
                            value: "Direct thermal",
                        },
                        {
                            type: "Resolution",
                            value: "203 DPI",
                        },
                        {
                            type: "Print speed",
                            value: "70mm(2.7\")/s",
                        },
                        {
                            type: "Print width",
                            value: "72mm(2.8\")",
                        },
                        {
                            type: "Paper width",
                            value: "20mm-76mm",
                        },
                        {
                            type: "Memory",
                            value: "8MB flash memory/ 8MB SDRAM",
                        },
                        {
                            type: "Sensor",
                            value: "Gap sensor\nCover opening sensor\nBlack mark sensor",
                        },
                        {
                            type: "Interface",
                            value: "Standard: USB+Bluetooth",
                        },
                        {
                            type: "Dimensions(W*D*H)",
                            value: "108*124*6lmm(W*D*H)",
                        },
                        {
                            type: "Weight",
                            value: "357g",
                        },
                        {
                            type: "Barcode ID",
                            value: "1D: Code 39, Code 93, Code 128 subsets A, B, C, Codabar, Interleaved 2 of 5, EAN-8, EAN-13, EAN- 128, UPC-A, UPC-E, EAN and UPC 2(5) digits add-on, MSI, PLESSEY, POSTNET, China POST\n\n2D: PDF-417, Maxicode, DataMatri, QR code, Aztec",
                        },
                        {
                            type: "Emulation/Programming Languages",
                            value: "TSPL, EPL, ZPL, DPL, CPCL, ESC/POS command compatible",
                        },
                        {
                            type: "Battery",
                            value: "2500mAh",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/downloads/brochure.pdf`,
                        },
                        {
                            name: "desktop application",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/downloads/apps/desktop_application.zip`,
                        },
                        {
                            name: "Android SDK 2.2.4",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/mobile_printers/MLP-370/downloads/apps/Android_SDK_2.2.4.zip`,
                        },
                    ],
                }
            ],
        },
        {
            id: "cash-drawers",
            image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers.png`,
            info: [
                {
                    id: "champ-cd-58410g",
                    title: "CHAMP CD 58410G",
                    image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/CHAMP-CD-58410G.png`,
                    description: "CHAMP CD 58410G is a multifunctional, reliable, secure Cash Drawer with 5 cash and 8 coin trays. It is designed and built with robust stainless steel construction. The steel guides and wear-resistant plastic rollers provide a smooth, wobble-free glide. The three-position lock with an open drawer sensor provides maximum cash protection.",
                    bullets: [
                        {
                            heaading: "advantages",
                            data: [
                                "Trays: 5 Notes & 8 Coin trays",
                                "Metal Body with Key Lock Option",
                                "Interface: CDK Port, 24 Volt",
                                "Durability: 1,000,000 operations",
                                "Casting: Stainless steel construction"
                            ]
                        }
                    ],
                    features: [
                        {
                          name: "24 VOLT OPERATION", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/1.png`,
                        },
                        {
                          name: "OPEN DRAWER SENSOR",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/2.png`,
                        },
                        {
                          name: "THREE-POSITION LOCK",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/5.png`,
                        },
                        {
                          name: "5 NOTE AND 8 COIN TRAYS", 
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/3.png`,
                        },
                        {
                          name: "INTEGRATED WITH RJ12 CONNECTOR",
                          image: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/4.png`,
                        },
                    ],
                    specifications: [
                        {
                            type: "Dimensions (inch)",
                            value: "16.1″W x 16.3″D x 3.9″H (inch, without Feet)",
                        },
                        {
                            type: "Dimensions (mm)",
                            value: "410 W x 415 D x 100 H (mm, without Feet)",
                        },
                        {
                            type: "Drawer",
                            value: "Metal cash drawer with heavy-duty ball bearing roller",
                        },
                        {
                            type: "Casing",
                            value: "Steel construction",
                        },
                        {
                            type: "Front Panel",
                            value: "Steel construction with painted front or stainless steel front (option).\nUsually the price is for the painted front. If a stainless steel front, the price should be 3.5 USD plused.",
                        },
                        {
                            type: "Bill Tray",
                            value: "Metal bill clips\nAdjustable dividers for up to 5 compartments (with 5 metal clips)",
                        },
                        {
                            type: "Coin Tray",
                            value: "Removable tray from the money case\nSolo–row tray adjustable dividers for 5 compartments\nDouble-row tray with adjustable dividers for 8 up comartments",
                        },
                        {
                            type: "Media slot",
                            value: "Front access slot for check, credit card receipt or any paper",
                        },
                        {
                            type: "Lockable Lid",
                            value: "Lockable Lid for the cash receiver to change tray (option)",
                        },
                        {
                            type: "Lock",
                            value: "3-position Key Lock",
                        },
                        {
                            type: "Micro Switch / Sensor",
                            value: "Open by driver signal to the solenoid (option)",
                        },
                        {
                            type: "I/O Interface",
                            value: "12V, 24V for RJ11 / RJ12 Receipt Printer Interface\n9-Pin RS-232 Interface (option)\nUSB Interface (option)",
                        },
                        {
                            type: "Durability",
                            value: "1,000, 000 operations",
                        },
                        {
                            type: "Colour",
                            value: "Ivory , Black or Customization",
                        },
                        {
                            type: "Packaging",
                            value: "465 W x 460 D x 150 H (mm)\n18.3″W x 18.1″D x  5.9″H (inch)",
                        },
                        {
                            type: "Net Weight",
                            value: "7.15 kgs",
                        },
                        {
                            type: "Gross Weight",
                            value: "8.15 kgs",
                        },
                    ],
                    downloads: [
                        {
                            name: "brochure",
                            path: `https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/products-page/cash_drawers/downloads/brochure.pdf`,
                        },
                    ],
                }
            ],
        },
    ]
};

export default individualProducts;