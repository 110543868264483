export const awards = [
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/awards/CRN+Award+2007.JPG",
        caption: 'CRN Award (2007)',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/awards/DQ+Week+Award+2007.jpg",
        caption: 'DQ Week Award (2007)',
        description: ' ',
    }
];

export const media = [
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/media/360+Magazine+-+072003+001.jpg",
        caption: '360 Magazine',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/media/Aashish+Bhai+1+001.jpg",
        caption: 'DQ Channel Index',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/media/Channel+Life-Hot+Seat-+ABP.jpg",
        caption: 'DQ Channel Life Hot Seat',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/media/DQ+Channel-LG.jpg",
        caption: 'DQ Channel LG',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/media/IT+Nation.jpg",
        caption: 'IT Nation',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/media/The+DQ+Week+Interview.jpg",
        caption: 'The DQ Week Interview',
        description: ' ',
    }
];

export const inaugration = [
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/AASH'NIK+Desktop.jpg",
        caption: 'AASH\'NIK Desktop',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/AASH(ISH)+OF+AASH'NIK.jpg",
        caption: 'AASH(ISH) of AASH\'NIK',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/AASHNIK+at+Night.jpg",
        caption: 'AASH\'NIK at Night',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/At+work.jpg",
        caption: 'At Work',
        description: ' ',
    },
    {
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/Brief+On+AASH'NIK's+Vision.jpg",
        caption: 'Brief on AASH\'NIK\'s Vision',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/Cabin.jpg",
        caption: 'Cabin',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/Explaining+AASH'NIK's+Mission.jpg",
        caption: 'Explaining AASH\'NIK\'s Mission',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/Father's+Note.jpg",
        caption: 'Father\'s Note',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/Invitation.jpg",
        caption: 'Invitation',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/Invitees.jpg",
        caption: 'Invitees',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/NIK(UNJ)+OF+AASH'NIK.jpg",
        caption: 'NIK(UNJ) of AASH\'NIK',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/The+Inougural+Note+In+Visitors+Book.jpg",
        caption: 'The Inaugral Note in Visitor\'s Book',
        description: ' ',
    },
    { 
        image: "https://aashnik.s3.ap-south-1.amazonaws.com/Assets/Images/gallery/inaugration/The+Man+Behind.jpg",
        caption: 'The Man Behind',
        description: ' ',
    }
];